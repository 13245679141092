import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
export class Level1FaqComponent {
    constructor(route, router, faqService, errorsService, location, pinsService) {
        this.route = route;
        this.router = router;
        this.faqService = faqService;
        this.errorsService = errorsService;
        this.location = location;
        this.pinsService = pinsService;
        this.a = null;
        this.d = null;
        this.p = null;
        this.errorStatus = null;
        this.errorMessage = null;
        this.isLoading = false;
        this.faqTree = null;
        this.level1Faq = null;
        this.faArrowRight = faArrowRight;
        this.pin = null;
    }
    ngOnInit() {
        this.route.queryParams.subscribe((p) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(' [Level1FaqComponent] + p --> ', p);
            this.a = p.a;
            this.d = p.d;
            this.p = p.p;
            console.log(' [Level1FaqComponent] + ngOnInit() --> this.a (targa) --> ', this.a);
            console.log(' [Level1FaqComponent] + ngOnInit() --> this.d (problemCode) --> ', this.d);
            try {
                this.faqTree = yield (yield this.faqService.getFaqFlussoGenerico(this.p, this.a)).toPromise();
            }
            catch (err) {
                console.log(err);
                return this.router.navigate(['error']);
            }
            console.log(' [Level1FaqComponent] + ngOnInit() --> this.faqTree -->', this.faqTree);
            // this.level1Faq = Object.keys(this.faqTree);
            // for (let i = 0; i < this.level1Faq.length; i++) {
            //   console.log(' + this.faqTree[' + this.level1Faq[i] + '] -->', this.faqTree[this.level1Faq[i]]);
            // }
            console.log(' [Level1FaqComponent] + ngOnInit() --> this.level1Faq -->', this.level1Faq);
        }), error => {
            console.log('Error message --->', error);
            this.router.navigate(['error']);
            // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
            // this.errorStatus = errObj.statusHeader;
            // this.errorMessage = errObj.statusMessage;
        });
    }
    onPinEvent(pin) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log(' + onPinEvent() --> pin --> ', pin);
            this.pin = pin;
            try {
                this.faqTree = yield (yield this.faqService.getFaqFlussoGenerico(this.p, this.a)).toPromise();
            }
            catch (err) {
                console.log(err);
                return this.router.navigate(['error']);
            }
            console.log(' [Level1FaqComponent] + ngOnInit() --> this.faqTree -->', this.faqTree);
            // this.level1Faq = Object.keys(this.faqTree);
            // for (let i = 0; i < this.level1Faq.length; i++) {
            //   console.log(' + this.faqTree[' + this.level1Faq[i] + '] -->', this.faqTree[this.level1Faq[i]]);
            // }
            console.log(' [Level1FaqComponent] + ngOnInit() --> this.level1Faq -->', this.level1Faq);
        });
    }
    onErrorEvent() {
        //this.isError = true;
    }
    onClickLevel1Faq(level1Faq) {
        console.log(' [Level1FaqComponent] + onClickLevel1Faq() --> level1Faq --> ', level1Faq);
        this.router.navigate(['level2-faq'], {
            queryParams: { level1Faq },
            queryParamsHandling: 'merge'
        });
    }
    onClickAltro() {
        console.log(' + onClickAltro()');
        this.router.navigate(['level2-faq'], {
            queryParamsHandling: 'preserve'
        });
    }
    onCloseAlert() {
        console.log(' [Level1FaqComponent] + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
        console.log(' [Level1FaqComponent] + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);
        this.errorStatus = null;
        this.errorMessage = null;
    }
}
