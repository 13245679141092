import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faqSms, faqSmsContainer } from "../models/faq-sms.model";
import { Pin } from "../models/pin.model";
import { ErrorsService } from "../services/errors.service";
import { FaqService } from "../services/faq.service";
import { PinsService } from "../services/pins.service";

export class FaqCaseComponent implements OnInit {

    p: number = null;
    a: string = null;
    b: string = null;
    d: string = null;
    c: string = null;
    note: string = null;
    errorStatus: string = null;
    errorMessage: string = null;
    isLoading = false;
    pin: Pin = null;
    faqTree: faqSmsContainer = null;
    level1Faq: string = null;
    currentIndex: number = 0;
    name:string='';


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private faqService: FaqService,
        private pinsService: PinsService,
        private errorsService: ErrorsService) {
        this.name = this.constructor.name
    }
    ngOnInit() {

        this.currentIndex = 0;
        this.route.queryParams.subscribe(async p => {
            console.log(' + p --> ', p);

            this.a = p.a; //targa
            this.b = p.b; //company 
            this.d = p.d; //problemCode
            this.p = p.p; //pin
            this.c = p.c; //imeiCode

            console.log(`[${this.name}] ==> ngOnInit() param a (targa) --> `, this.a);
            console.log(`[${this.name}] ==> ngOnInit() param b (company) --> ` , this.b);
            console.log(`[${this.name}] ==> ngOnInit() param d (problemCode) --> `, this.d);
            console.log(`[${this.name}] ==> ngOnInit() param p (pin) --> `, this.p);
            console.log(`[${this.name}] ==> ngOnInit() param c   --> `, this.c);
            if (!this.a || !this.d) {
                this.errorStatus = 'Non è possibile continuare la navigazione';
                this.errorMessage = 'La targa e il problem code sono obbligatori';
            }
            try {
                this.faqTree = (await this.faqService.getFaqFlussoSMS(this.d, this.c).toPromise() as faqSmsContainer);
                console.log(`[${this.name}] + ngOnInit() --> this.faqTree -->`, this.faqTree);
                if(this.faqTree){
                    this.faqTree.faq.forEach(faqElement => {
                        console.log(`[${this.name}] + ngOnInit() --> faq -->`, faqElement);
                    });
                }
                this.pin = await this.pinsService.pinData(this.a, this.p, 'S');
            } catch (err) {
                console.log(err);

                return this.router.navigate(['error']);
            }

            console.log(' + ngOnInit() --> Object Pin -->', this.pin);

            // this.faqTree.forEach(faqElement => {
            //   faqElement = null;
            // });

            console.log(`[${this.name}] ==> ngOnInit() faqTree -->`, this.faqTree);
        }, error => {
            console.log(`[${this.name}] ==> Error message ==>`, error);

            // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
            // this.errorStatus = errObj.statusHeader;
            // this.errorMessage = errObj.statusMessage;
            this.router.navigate(['error']);
        });








    }

    onClickOptions(faq: faqSms, optionType: string) {

        if (optionType == 'yes') {

            if (faq.SI != null && faq.SI.risposta != '') {
                faq.isVisible = true;
                this.note = faq.note;
            } else {
                faq.isVisible = false;
            }

        } else if (optionType == 'no') {

            if (faq.NO != null && faq.NO.risposta != '') {
                faq.isVisible = true;
                this.note = faq.note;
            } else {
                faq.isVisible = false;
            }
        }
        console.log("optionType: ", optionType);
        console.log("faq: ", faq);

    }

    onRegistra(event: PointerEvent) {

        console.log(`[${this.name} ] onRegistra() ==> faqTree -->` , this.faqTree);
        console.log(`[${this.name} ] onRegistra() ==> faqTree.exit_message`, this.faqTree.exit_message);
        console.log(`[${this.name} ] onRegistra() ==> faqTree.faq` , this.faqTree.faq);

        event.preventDefault();
        this.level1Faq = '';
        let exit_message = '';
        let exit = null;
        let exitID = null;
        
        console.log(`[${this.name} ] onRegistra() ==>   faq `, this.faqTree.faq);


        for (let i = 0; i < this.faqTree.faq.length; i++) {
            console.log(`[${this.name} ] onRegistra() ==>   faqTree.faq[i].nome `, this.faqTree.faq[i].nome);


            const risposta = this.faqTree.faq[i].opzioneUtente ? 'sì' : 'no';
            // let note = (this.faqTree[i].note != "" && this.faqTree[i].note != undefined) ? this.faqTree[i].note : '';
            // this.level1Faq += this.faqTree[i].testo + ' --> ' + 'risposta: ' + risposta + ' note: ' + note;
            // let note = (this.faqTree[i].note != "" && this.faqTree[i].note != undefined) ? this.faqTree[i].note : '';
            this.level1Faq += this.faqTree.faq[i].testo + ' --> ' + 'risposta: ' + risposta;

            if (typeof (this.faqTree.faq[i].SI.exit) != 'undefined' && this.faqTree.faq[i].opzioneUtente == 1) {
                //console.log(this.faqTree.faq[i]);

                if (exit > this.faqTree.faq[i].SI.exit || exit == null)
                    exit = this.faqTree.faq[i].SI.exit;
                exitID = exit;
            }
            else if (typeof (this.faqTree.faq[i].NO.exit) != 'undefined' && this.faqTree.faq[i].opzioneUtente == 0) {
                //console.log(this.faqTree.faq[i]);

                if (exit > this.faqTree.faq[i].NO.exit || exit == null)
                    exit = this.faqTree.faq[i].NO.exit;
                exitID = exit;
            }

            if (exit > 0)
                exit_message = this.faqTree.exit_message[exit];
            exitID = exit;

            if (i < this.faqTree.faq.length - 1) {
                // separator
                this.level1Faq += ' - ';
            }
        }
        console.log(` [${this.name} ] + onRegistra() ==>   level1Faq --> `, this.level1Faq);
        console.log(` [${this.name} ] + onRegistra() ==>   faqTree.note --> `, this.faqTree.note);
        console.log(` [${this.name} ] + onRegistra() ==>   faqTree.email --> `, this.faqTree.email);
        console.log(` [${this.name} ] + onRegistra() ==>   exit_message --> `, exit_message);
        console.log(` [${this.name} ] + onRegistra() ==>   p --> `, this.p);
        console.log(` [${this.name} ] + onRegistra() ==>   exit_code --> `, exitID);

        this.clearSessionStorage();

        this.router.navigate(
            ['pin-page'],
            {
                queryParams: {
                    a: this.a,
                    b: this.b,
                    level1Faq: this.level1Faq,
                    note: this.faqTree.note != '' ? this.faqTree.note : '',
                    exit: exit_message,
                    paramExitID: exitID,
                    communicationType: this.faqTree.email ? 'si' : 'no',
                    p: this.p // pin
                },
                queryParamsHandling: 'merge'
            }
        );
    }

    onCloseAlert() {

        //console.log(' + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
        //console.log(' + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);

        this.errorStatus = null;
        this.errorMessage = null;
    }
    clearSessionStorage() {
        if (sessionStorage.getItem('PinStorage')) {
            console.log(`[${this.name}]::: (clearSessionStorage) CLEARING SESSION STORAGE :::`);
            sessionStorage.removeItem('PinStorage');
        }
    }
}
