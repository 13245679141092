<div class="text-center mb-5">
  <img class="mb-4" src="../../../assets/unipolgruppo_tricolore.png" alt="Unipol logo">

  <h3 class="mt-4">
    Questa pagina web è dedicata all'assistenza degli Assicurati che hanno sottoscritto una polizza RC abbinata ad un
    dispositivo telematico.
  </h3>
</div>

<h4 class="text-center mb-5">Per quale targa vuoi assistenza?</h4>

<form class="text-center" [formGroup]="targaForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="form-group mb-5">
    <input type="text" id="targa" formControlName="targa" name="targa"
      class="mu-form-control form-control form-control-sm" #targa
      [ngClass]="{ 'is-invalid': form.targa.invalid && form.targa.touched }" placeholder="TE123ST">

    <div *ngIf="form.targa.errors?.required" class="invalid-feedback">
      <span>La targa è obbligatoria</span>
    </div>

    <!-- <div *ngIf="form.targa.errors?.invalidTarga" class="invalid-feedback">
      <span>Il formato della targa è errato</span>
    </div> -->
  </div>

  <div class="text-center mu-fixed-btn-container">
    <button class="mu-btn btn btn-primary" type="submit" [disabled]="!targaForm.valid">Procedi</button>
  </div>
</form>