import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Pin } from 'src/app/models/pin.model';

@Component({
  selector: 'app-contract-link',
  templateUrl: './contract-link.component.html',
  styleUrls: ['./contract-link.component.scss']
})
export class ContractLinkComponent implements OnInit {

  @Input() targa: string;
  @Input() companyId: string;
  @Input() anomalyCode: string;
  @Input() enabled: boolean;
  @Input() mode: string;
  //enabled: boolean = false;

  constructor(private router: Router) { 
    this.mode = 'top';
  }

  ngOnInit() {
    this.checkPin();
  } 

  onUpdateEvent() {
    this.checkPin();
  }

  checkPin(){
    if (sessionStorage.getItem('PinStorage')) {
      let pin =  JSON.parse(sessionStorage.getItem('PinStorage'));
      console.log('[ContractLinkComponent]  ::::: current pin ' + pin);
      if(pin && pin.competencePlate != 'I')
        this.enabled = true;
    }
  }
  
  gotoContractStatus(){
    if(this.enabled)
      this.router.navigate(['/contract-page'],{
        queryParams: {
          targa: this.targa,
          companyId: this.companyId,
          anomalyCode: this.anomalyCode
        }
      });
  }
}
