import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PinsService } from '../../services/pins.service';
import { ErrorMeta } from 'src/app/models/errorMeta.model';
import { ErrorsService } from 'src/app/services/errors.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sms-page',
  templateUrl: './sms-page.component.html',
  styleUrls: ['./sms-page.component.scss']
})
export class SmsPageComponent implements OnInit {

  a: string = null; // targa
  b: string = null; // companyId (1 char)
  c: string = null; // imei
  d: string = null; // anomalyCode (3 digits)
  isLoading = false;
  errorStatus: string = null;
  errorMessage: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pinsService: PinsService,
    private errorsService: ErrorsService
  ) { }

  ngOnInit() { 
    this.route.queryParams.subscribe(p => {
      console.log('SmsPageComponent.ngOnInit: parameters => ', p);
      if(p.s){
        this.pinsService.getSMSDetails(p.s).subscribe(        
          response => {
            this.a = response.vehiclePlate;
            this.b = response.companyId;
            this.c = response.imeiCode;
            this.d = response.anomalyCode && response.anomalyCode.length > 3 ? response.anomalyCode.substr(response.anomalyCode.length-3) : response.anomalyCode;
            this.processParameters();
          },
          error => {
            console.log('SmsPageComponent.ngOnInit: getSMSDetails failed ', error);      
            this.router.navigate(['error']);
          }
        );
      }
      else{
        this.a = p.a;
        this.b = p.b;
        this.c = p.c;
        this.d = p.d;
        this.processParameters();
      }
    },
    error => {
      console.log('SmsPageComponent.ngOnInit: failed (missing parameters)', error);    
      this.router.navigate(['error']);
    });
  }

  processParameters(){
    console.log('SmsPageComponent.processParameters: this.a (targa) --> ', this.a);
    console.log('SmsPageComponent.processParameters: this.b (company) --> ', this.b);
    console.log('SmsPageComponent.processParameters: this.c (imei) --> ', this.c);
    console.log('SmsPageComponent.processParameters: this.d (anomaly) --> ', this.d);

    const anomalyCode:string[] =  environment.anomalyCodes
    
    const params = {
      a: this.a,
      b: this.b,
      c: this.c,
      d: this.d,
      ts: new Date().valueOf()
    };

    //if(['000','004','009','007','016','017'].indexOf(this.d) >= 0){
    if(anomalyCode.includes(this.d) || this.d==='000'){
      console.log('SmsPageComponent.processParameters: case '+this.d);
      this.router.navigate( ['sms-page-case'+this.d], { queryParams: params });
    }
    else{
      console.log('SmsPageComponent.processParameters: bad anomaly code');
      this.router.navigate(['error']);
    }
  }
/*
  onCloseAlert() {

    console.log(' + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
    console.log(' + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);

    this.errorStatus = null;
    this.errorMessage = null;

    this.router.navigate(['/home']);
  }*/
}
