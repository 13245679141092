/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sms-flow-end.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sms-flow-end.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
var styles_SmsFlowEndComponent = [i0.styles];
var RenderType_SmsFlowEndComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmsFlowEndComponent, data: {} });
export { RenderType_SmsFlowEndComponent as RenderType_SmsFlowEndComponent };
export function View_SmsFlowEndComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "text-center mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Gentile cliente, la procedura \u00E8 gi\u00E0 stata completata."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "text-center mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Se vuole ricominciarla da capo, le chiediamo di usare nuovamente il link che ha ricevuto via SMS. "]))], null, null); }
export function View_SmsFlowEndComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sms-flow-end", [], null, null, null, View_SmsFlowEndComponent_0, RenderType_SmsFlowEndComponent)), i1.ɵdid(1, 245760, null, 0, i2.SmsFlowEndComponent, [i3.Location, i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmsFlowEndComponentNgFactory = i1.ɵccf("app-sms-flow-end", i2.SmsFlowEndComponent, View_SmsFlowEndComponent_Host_0, {}, {}, []);
export { SmsFlowEndComponentNgFactory as SmsFlowEndComponentNgFactory };
