/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./level2-faq.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../shared/targa/targa.component.ngfactory";
import * as i6 from "../../shared/targa/targa.component";
import * as i7 from "@angular/common";
import * as i8 from "@angular/forms";
import * as i9 from "../../shared/contract-link/contract-link.component.ngfactory";
import * as i10 from "../../shared/contract-link/contract-link.component";
import * as i11 from "@angular/router";
import * as i12 from "./level2-faq.component";
import * as i13 from "../../services/faq.service";
import * as i14 from "../../services/pins.service";
var styles_Level2FaqComponent = [i0.styles];
var RenderType_Level2FaqComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Level2FaqComponent, data: {} });
export { RenderType_Level2FaqComponent as RenderType_Level2FaqComponent };
function View_Level2FaqComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn mu-btn mu-floating-btn btn-primary mb-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "text-center mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "text-center mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clicca sulle voci sottostanti per informazioni pi\u00F9 approfondite."])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "p", [["class", "text-center mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Seleziona quella pi\u00F9 pertinente alla tua esigenza, aggiungi eventuali note e poi clicca su \""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Registra e Procedi"])), (_l()(), i1.ɵted(-1, null, ["\":"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faArrowLeft; _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).title; var currVal_1 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.faqTitle; _ck(_v, 5, 0, currVal_3); }); }
function View_Level2FaqComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn mu-btn mu-floating-btn btn-primary mb-3"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(2, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "text-center mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Descrivi nelle note l'argomento per cui richiedi assistenza: "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-targa", [], null, null, null, i5.View_TargaComponent_0, i5.RenderType_TargaComponent)), i1.ɵdid(6, 49152, null, 0, i6.TargaComponent, [], { targa: [0, "targa"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faArrowLeft; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.a; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).title; var currVal_1 = i1.ɵnov(_v, 2).renderedIconHTML; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_Level2FaqComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faCheckSquare; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_Level2FaqComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faSquare; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_Level2FaqComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.testoFaq; _ck(_v, 1, 0, currVal_0); }); }
function View_Level2FaqComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level2FaqComponent_8)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.index === 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_Level2FaqComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mu-list-group-item-description"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level2FaqComponent_7)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.faqs; _ck(_v, 2, 0, currVal_0); }, null); }
function View_Level2FaqComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "mu-list-group-item mu-list-group-item-2nd-level list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickLevel2Faq(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "span", [["class", "mu-list-group-item-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level2FaqComponent_4)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level2FaqComponent_5)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level2FaqComponent_6)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "mu-list-group-item mu-list-group-item-2nd-level list-group-item"; var currVal_1 = (_v.context.$implicit.isVisible ? "active" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.isVisible; _ck(_v, 6, 0, currVal_3); var currVal_4 = !_v.context.$implicit.isVisible; _ck(_v, 8, 0, currVal_4); var currVal_5 = _v.context.$implicit.isVisible; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_Level2FaqComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "form-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["class", "form-check-input"], ["id", "email"], ["name", "email"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.email = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i8.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i8.NgModel, [[2, i8.ControlContainer], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(6, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["class", "form-check-label"], ["for", "email"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Desideri continuare questo dialogo via email? "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "email"; var currVal_8 = _co.email; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_Level2FaqComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contract-link", [], null, null, null, i9.View_ContractLinkComponent_0, i9.RenderType_ContractLinkComponent)), i1.ɵdid(1, 114688, null, 0, i10.ContractLinkComponent, [i11.Router], { targa: [0, "targa"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level2FaqComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLevel1Faq", 2]], null, 0, null, View_Level2FaqComponent_2)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [["class", "mu-list-group list-group list-group-flush mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level2FaqComponent_3)), i1.ɵdid(7, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 18, "form", [["class", "w-100"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i8.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(10, 4210688, null, 0, i8.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i8.ControlContainer, null, [i8.NgForm]), i1.ɵdid(12, 16384, null, 0, i8.NgControlStatusGroup, [[4, i8.ControlContainer]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 10, "div", [["class", "textarea-margin-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [["class", "form-group "]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 6, "textarea", [["class", "mu-form-control mu-textarea form-control"], ["id", "note"], ["name", "note"], ["placeholder", "Note..."], ["rows", "4"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.note = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i8.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i8.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DefaultValueAccessor]), i1.ɵdid(18, 671744, null, 0, i8.NgModel, [[2, i8.ControlContainer], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(20, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level2FaqComponent_9)), i1.ɵdid(23, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 2, "div", [["class", "text-center mu-fixed-btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "mu-btn btn btn-primary"], ["id", "procediButton"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onProcedi($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Registra e Procedi"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.a; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.level1Faq; var currVal_2 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = ((_co.faqs == null) ? null : _co.faqs.faqLivello2); _ck(_v, 7, 0, currVal_3); var currVal_18 = "note"; var currVal_19 = _co.note; _ck(_v, 18, 0, currVal_18, currVal_19); var currVal_20 = ((_co.pin == null) ? null : _co.pin.hasEmail); _ck(_v, 23, 0, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 12).ngClassValid; var currVal_9 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_12 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_13 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_14 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_15 = i1.ɵnov(_v, 20).ngClassValid; var currVal_16 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_17 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 15, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_21 = (!_co.level2Faq && !_co.note); _ck(_v, 25, 0, currVal_21); }); }
export function View_Level2FaqComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-level2-faq", [], null, null, null, View_Level2FaqComponent_0, RenderType_Level2FaqComponent)), i1.ɵdid(1, 114688, null, 0, i12.Level2FaqComponent, [i11.ActivatedRoute, i13.FaqService, i11.Router, i7.Location, i14.PinsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Level2FaqComponentNgFactory = i1.ɵccf("app-level2-faq", i12.Level2FaqComponent, View_Level2FaqComponent_Host_0, {}, {}, []);
export { Level2FaqComponentNgFactory as Level2FaqComponentNgFactory };
