import { Component, OnInit } from '@angular/core';
import { Pin } from '../../models/pin.model';
import { ActivatedRoute, Router, NavigationStart ,NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-sms-page-case009',
  templateUrl: './sms-page-case009.component.html',
  styleUrls: ['./sms-page-case009.component.scss']
})
export class SmsPageCase009Component implements OnInit {

  a: string = null;
  b: string = null;
  c: string = null;
  d: string = null;
  pin: Pin = null;
  isError = false;
  resultDays: number;



  constructor(
    private route: ActivatedRoute,
    private router: Router) {
      
  }



  ngOnInit() {
    this.route.queryParams.subscribe(p => {
      console.log(' + p --> ', p);
      this.a = p.a;
      this.b = p.b;
      this.c = p.c;
      this.d = p.d;

      console.log(' [SmsPageCase009Component] + ngOnInit() --> this.a (targa)--> ', this.a);
      console.log(' [SmsPageCase009Component]  + ngOnInit() --> this.b (companyId)--> ', this.b);
      console.log('  [SmsPageCase009Component]  + ngOnInit() --> this.c (imeiCode) --> ', this.c);
      console.log('  [SmsPageCase009Component] + ngOnInit() --> this.d (problemCode)--> ', this.d);

    });

  }

  onPinEvent(pin: Pin) {
    console.log(' + onPinEvent() --> pin --> ', pin);

    this.pin = pin;
  }

  onErrorEvent() {
    this.isError = true;
  }

  onProcedi() {
    this.router.navigate(
      ['faq-case009'],
      {
        queryParams: {
          p: this.pin.pin,
          b: this.b,
          created: new Date(this.pin.created).getTime(),
          expirationDate: new Date(this.pin.expirationDate).getTime()
        },
        queryParamsHandling: 'merge'
      }
    );
  }
}
