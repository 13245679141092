// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Components
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { SmsPageComponent } from './components/sms-page/sms-page.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { AlertComponent } from './shared/alert/alert.component';
import { TargaComponent } from './shared/targa/targa.component';
import { Level2FaqComponent } from './components/level2-faq/level2-faq.component';
import { PinPageComponent } from './components/pin-page/pin-page.component';
import { SmsPageCase009Component } from './components/sms-page-case009/sms-page-case009.component';
import { SmsPageCase004Component } from './components/sms-page-case004/sms-page-case004.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SmsPageCase000Component } from './components/sms-page-case000/sms-page-case000.component';
import { PinComponent } from './shared/pin/pin.component';
import { Level1FaqComponent } from './components/level1-faq/level1-faq.component';
import { FaqCase004Component } from './components/faq-case004/faq-case004.component';
import { FaqCase009Component } from './components/faq-case009/faq-case009.component';
import { PageErrorComponent } from './components/page-error/page-error.component';
import { DeviceOkTemplateComponent } from './components/device-ok-template/device-ok-template.component';

import { SmsFlowEndComponent } from './components/sms-flow-end/sms-flow-end.component';
import { DatePipe } from '@angular/common';


// Custom Directives
import { TrimInputDirective } from './directives/trim-input.directive';
import { ContractPageComponent } from './components/contract-page/contract-page.component';
import { ContractLinkComponent } from './shared/contract-link/contract-link.component';
import { SmsPageCase007Component } from './components/sms-page-case007/sms-page-case007.component';
import { SmsPageCase016Component } from './components/sms-page-case016/sms-page-case016.component';
import { SmsPageCase017Component } from './components/sms-page-case017/sms-page-case017.component';
import { FaqCase007Component } from './components/faq-case007/faq-case007.component';
import { FaqCase016Component } from './components/faq-case016/faq-case016.component';
import { FaqCase017Component } from './components/faq-case017/faq-case017.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SmsPageComponent,
    FooterComponent,
    LoadingSpinnerComponent,
    AlertComponent,
    TargaComponent,
    Level2FaqComponent,
    PinPageComponent,
    SmsPageCase009Component,
    SmsPageCase004Component,
    PageNotFoundComponent,
    SmsPageCase000Component,
    AppComponent,
    PinComponent,
    Level1FaqComponent,
    FaqCase004Component,
    FaqCase009Component,
    PageErrorComponent,
    DeviceOkTemplateComponent,
    TrimInputDirective,
    SmsFlowEndComponent,
    ContractPageComponent,
    ContractLinkComponent,
    SmsPageCase007Component,
    SmsPageCase016Component,
    SmsPageCase017Component,
    FaqCase007Component,
    FaqCase016Component,
    FaqCase017Component
  ],
  imports: [
    BrowserModule,
    // BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
