export interface faqSms {
    nome: string;
    testo: string;
    isVisible: boolean;
    note: string;
    opzioneUtente: number;
    SI: any;
    NO: any;
    faqLivello2: any[];
}
export interface exit_message {
    testo: string;
}

export interface faqSmsContainer {
    faq: faqSms[];
    exit_message: string[];
    email: string;
    note: string;
}


export class faqSms009 implements faqSmsContainer {
    faq: faqSms[];
    exit_message: string[];
    email: string;
    note: string;
  }

