/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq-case009.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "../../shared/loading-spinner/loading-spinner.component";
import * as i4 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "../../shared/contract-link/contract-link.component.ngfactory";
import * as i10 from "../../shared/contract-link/contract-link.component";
import * as i11 from "@angular/router";
import * as i12 from "./faq-case009.component";
import * as i13 from "../../services/faq.service";
import * as i14 from "../../services/errors.service";
import * as i15 from "../../services/pins.service";
var styles_FaqCase009Component = [i0.styles];
var RenderType_FaqCase009Component = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqCase009Component, data: {} });
export { RenderType_FaqCase009Component as RenderType_FaqCase009Component };
function View_FaqCase009Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingSpinnerComponent, [], null, null)], null, null); }
function View_FaqCase009Component_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faCheckSquare; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FaqCase009Component_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.faSquare; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FaqCase009Component_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "mu-list-group-item-description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "mb-3"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "textarea", [["class", "mu-form-control mu-textarea form-control"], ["id", "note"], ["name", "note"], ["placeholder", "Note..."], ["rows", "4"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_v.parent.context.$implicit.note = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(5, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(7, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["      "]))], function (_ck, _v) { var currVal_8 = "note"; var currVal_9 = _v.parent.context.$implicit.note; _ck(_v, 5, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.testo; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_FaqCase009Component_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "list-group-item mu-list-group-item mu-list-group-item-2nd-level"]], null, null, null, null, null)), i1.ɵprd(512, null, i8.ɵNgClassImpl, i8.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i8.NgClass, [i8.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "span", [["class", "mu-list-group-item-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickLevel1Faq(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqCase009Component_4)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqCase009Component_5)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqCase009Component_6)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = "list-group-item mu-list-group-item mu-list-group-item-2nd-level"; var currVal_1 = (_v.context.$implicit.isVisible ? "active" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.isVisible; _ck(_v, 6, 0, currVal_3); var currVal_4 = !_v.context.$implicit.isVisible; _ck(_v, 8, 0, currVal_4); var currVal_5 = _v.context.$implicit.isVisible; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.nome; _ck(_v, 4, 0, currVal_2); }); }
function View_FaqCase009Component_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "form-check margin-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["class", "form-check-input"], ["id", "email"], ["name", "email"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.faqTree.email = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i7.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(6, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["class", "form-check-label"], ["for", "email"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Desideri continuare questo dialogo via email? "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "email"; var currVal_8 = _co.faqTree.email; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_FaqCase009Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contract-link", [], null, null, null, i9.View_ContractLinkComponent_0, i9.RenderType_ContractLinkComponent)), i1.ɵdid(1, 114688, null, 0, i10.ContractLinkComponent, [i11.Router], { targa: [0, "targa"], companyId: [1, "companyId"], anomalyCode: [2, "anomalyCode"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "h3", [["class", "text-center mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Seleziona la voce corrispondente alla tua condizione, aggiungi se vuoi altre informazioni nel campo \"note\" in fondo alla pagina, poi clicca su \""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Registra e Procedi"])), (_l()(), i1.ɵted(-1, null, ["\" per inviarci la risposta:"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ul", [["class", "mu-list-group list-group list-group-flush margin-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqCase009Component_3)), i1.ɵdid(9, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqCase009Component_7)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "text-center mu-fixed-btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "mu-btn btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRegistra() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Registra e Procedi"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.a; var currVal_1 = _co.b; var currVal_2 = "009"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.faqTree.faq; _ck(_v, 9, 0, currVal_3); var currVal_4 = ((_co.pin == null) ? null : _co.pin.hasEmail); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = !_co.level1Faq; _ck(_v, 13, 0, currVal_5); }); }
export function View_FaqCase009Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqCase009Component_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["contentBlock", 2]], null, 0, null, View_FaqCase009Component_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FaqCase009Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq-case009", [], null, null, null, View_FaqCase009Component_0, RenderType_FaqCase009Component)), i1.ɵdid(1, 114688, null, 0, i12.FaqCase009Component, [i11.ActivatedRoute, i11.Router, i13.FaqService, i14.ErrorsService, i15.PinsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqCase009ComponentNgFactory = i1.ɵccf("app-faq-case009", i12.FaqCase009Component, View_FaqCase009Component_Host_0, {}, {}, []);
export { FaqCase009ComponentNgFactory as FaqCase009ComponentNgFactory };
