import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KpiLogsService, KpiLogEvent } from 'src/app/services/kpi-logs.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  targaForm: FormGroup;
  errorStatus: string = null;
  errorMessage: string = null;
  browserName: string;
  browserNotSupportedMsg = 'Questo browser non è supportato, utilizzane uno tra i seguenti: Chrome, Firefox, Microsoft Edge e Safari.';

  constructor(
    private router: Router,
    private kpiLogsService: KpiLogsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.targaForm = new FormGroup({
      targa: new FormControl(null, Validators.required)
    });
    this.checkBrowser();

    this.route.queryParams.subscribe(async p => {
      console.log('[HomeComponent] + p --> ', p);
      if (!(typeof (p.ts) != 'undefined'))
        this.router.navigate(['/home'], { queryParams: { ts: new Date().valueOf() } });
    }, error => {
      console.log('[HomeComponent] Error message ---> ', error);
    });

    this.kpiLogsService.registerKPILogs(KpiLogEvent.SITE_ACCESS_EVENT);
  }

  onSubmit() {
    console.log(' [HomeComponent] + onSubmit() --> this.targaForm --> ', this.targaForm);
    this.clearSessionStorage();

    const a = this.targaForm.value.targa.toUpperCase();

    console.log(' [HomeComponent] + onSubmit() --> targa --> ', a);


    this.router.navigate(['/sms-page'], { queryParams: { a, d: '000', ts: new Date().valueOf() } });

  }

  get form() {
    return this.targaForm.controls;
  }

  clearSessionStorage(){
    if (sessionStorage.getItem('PinStorage')) {
      console.log('::: (clearSessionStorage) CLEARING SESSION STORAGE :::');
      sessionStorage.removeItem('PinStorage');
    }
  }

  checkBrowser() {

    // Get the user-agent string
    const userAgentString =
      navigator.userAgent;

    // Detect Chrome
    let chromeAgent =
      userAgentString.indexOf('Chrome') > -1;

    // Detect Internet Explorer
    let IExplorerAgent =
      userAgentString.indexOf('MSIE') > -1 ||
      userAgentString.indexOf('rv:') > -1;

    // Detect Firefox
    const firefoxAgent =
      userAgentString.indexOf('Firefox') > -1;

    // Discard IExplorer since it also matches Firefox
    if ((firefoxAgent) && (IExplorerAgent)) {
      IExplorerAgent = false;
    }

    // Detect Safari
    let safariAgent =
      userAgentString.indexOf('Safari') > -1;

    // Discard Safari since it also matches Chrome
    if ((chromeAgent) && (safariAgent)) {
      safariAgent = false;
    }

    // Detect Opera
    const operaAgent =
      userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if ((chromeAgent) && (operaAgent)) {
      chromeAgent = false;
    }

    const edgeAgent =
      userAgentString.indexOf('Edg') > -1;

    // Discard Chrome since it also matches Edge
    if ((edgeAgent) && (chromeAgent)) {
      chromeAgent = false;
    }


    console.log('isChrome?', chromeAgent);
    console.log('isIExplorer?', IExplorerAgent);
    console.log('isFireFox?', firefoxAgent);
    console.log('isSafari?', safariAgent);
    console.log('isOpera?', operaAgent);
    console.log('isEdge?', edgeAgent);

    if ((!edgeAgent) && (!chromeAgent) && (!firefoxAgent) && (!safariAgent)) {
      alert(this.browserNotSupportedMsg);
    }
  }
}
