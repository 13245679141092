import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SmsPageCaseComponent } from 'src/app/core/sms-page-case-component';
import { Pin } from 'src/app/models/pin.model';

@Component({
  selector: 'app-sms-page-case017',
  templateUrl: './sms-page-case017.component.html',
  styleUrls: ['./sms-page-case017.component.scss']
})
export class SmsPageCase017Component extends SmsPageCaseComponent implements OnInit {

  constructor(
    route: ActivatedRoute,
    router: Router
  ) {
    super(route, router, 'faq-case017')
  }

  onPinEvent(pin: Pin) {
   

    super.onPinEvent(pin)

  }
}
