import * as tslib_1 from "tslib";
import { environment } from 'src/environments/environment';
import { Pin } from '../models/pin.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PinsService {
    constructor(http) {
        this.http = http;
        this.base_url = environment.base_url;
    }
    getPinsFlussoGenerico(targa, pin) {
        return this.http.get(this.base_url + '/genericflow/' + 'pins' + '?vehiclePlate=' + targa + '&pin=' + pin);
    }
    getPinsSms(targa, pin) {
        return this.http.get(this.base_url + '/smsflow/' + 'pins' + '?vehiclePlate=' + targa + '&pin=' + pin);
    }
    createPinFlussoGenerico(targa, faqData) {
        const inputData = {
            vehiclePlate: targa,
        };
        return this.http.post(this.base_url + '/genericflow/' + 'pins', inputData);
    }
    patchPinFlussoGenerico(id, data) {
        return this.http.patch(this.base_url + '/genericflow/' + 'pins/' + id, data);
    }
    getPinsFlussoSMS(a, p, d = '004' || '009') {
        return this.http.get(`${this.base_url}/smsflow/pins?vehiclePlate=${a}&pin=${p}`);
    }
    createPinFlussoSMS(a, b = "U" || "L" || "A", c, d = '004' || '009') {
        const inputData = {
            vehiclePlate: a,
            companyId: b,
            imeiCode: c,
            anomalyCode: d
        };
        let url = '';
        url = url.concat(this.base_url, '/smsflow/pins');
        return this.http.post(url, inputData);
    }
    patchPinFlussoSms(id, data) {
        console.log('[PinsService-> patchPinFlussoSms] --> ', ' id ', id, ' data ', data);
        return this.http.patch(this.base_url + '/smsflow/' + 'pins/' + id, data);
    }
    pinData(targa, p, type = 'G' || 'S') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let pin;
            let pinData = null;
            try {
                if (type == 'G')
                    pinData = yield this.getPinsFlussoGenerico(targa, p).toPromise();
                else if (type == 'S')
                    pinData = yield this.getPinsFlussoSMS(targa, p).toPromise();
            }
            catch (error) {
                console.error(error);
                throw error;
            }
            console.log(' pinsService.getPinsFlussoGenerico(' + targa + ') --> ', pinData);
            pin = new Pin(null, targa, new Date(), new Date(), 'M', null, null, 'R', null, null, null, null, null, null, false, new Date());
            pin.pin = pinData.pin;
            pin.vehiclePlate = pinData.vehiclePlate;
            pin.created = pinData.created;
            pin.expirationDate = pinData.expirationDate;
            pin.competencePlate = pinData.competencePlate;
            pin.email = pinData.email;
            pin.deviceType = pinData.deviceType;
            pin.codeMessage = pinData.codeMessage;
            pin.hasEmail = pinData.hasEmail;
            pin.communicationType = pinData.deviceType;
            pin.otherInfo = pinData.otherInfo;
            pin.contractId = pinData.contractId;
            pin.insurancePolicy = pinData.insurancePolicy;
            pin.deviceStatus = pinData.deviceStatus;
            pin.insuranceStartDate = pinData.insuranceStartDate;
            return pin;
        });
    }
    getContractInfo(plate, company) {
        return this.http.get(`${this.base_url}/contractInfo?vehiclePlate=${plate}` + (company ? `&companyId=${company}` : ''));
    }
    getSMSDetails(smsId) {
        return this.http.get(`${this.base_url}/getSMSDetails?smsLog=${smsId}`
        //`http://localhost:3000/api/getSMSDetails?smsLog=${smsId}`
        );
    }
}
PinsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PinsService_Factory() { return new PinsService(i0.ɵɵinject(i1.HttpClient)); }, token: PinsService, providedIn: "root" });
