<!-- <app-alert [status]="errorStatus" [message]="errorMessage" *ngIf="errorStatus" (closeAlert)="onCloseAlert()">
</app-alert> -->

<app-loading-spinner *ngIf="isLoading; else contentBlock"></app-loading-spinner>

<ng-template #contentBlock>
  <app-contract-link [targa]="a"></app-contract-link>
  <h3 class="text-center mb-2">Consulta le FAQ selezionando l'argomento di tuo interesse:</h3>
  <!-- <app-targa [targa]="a"></app-targa> -->

  <ul class="mu-list-group list-group list-group-flush">
    <li class="mu-list-group-item list-group-item" *ngFor="let faq of faqTree" (click)="onClickLevel1Faq(faq.id)">
      <span class="mu-list-group-item-text">

        <!--https://stackoverflow.com/questions/39628007/angular2-innerhtml-binding-remove-style-attribute/39630507-->
        <div [innerHtml]="faq.name"></div>
      </span>
      <!-- <i class="fa fa-arrow-right"></i> -->
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </li>

    <li class="mu-list-group-item list-group-item mb-5" (click)="onClickAltro()">
      <span class="mu-list-group-item-text">Altro/Nessuna di queste</span>
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </li>
  </ul>
</ng-template>