<!-- <app-alert [status]="errorStatus" [message]="errorMessage" *ngIf="errorStatus" (closeAlert)="onCloseAlert()">
</app-alert> -->

<app-loading-spinner *ngIf="isLoading; else contentBlock"></app-loading-spinner>

<!--Template flusso generico competenza "Octo" o "Meta"-->
<ng-template #contentBlock>
  <div *ngIf="(competence === 'O' || competence === 'M') && problemCode === '000'; else caso009Else">
    <div *ngIf=" !isLoading && !errorStatus && !handleErrorMessage">
      <h3 class="text-center mb-3">Per ricevere assistenza per la targa</h3>
      <app-targa [targa]="targa"></app-targa>
      <p class="text-center row-margin"> annota il seguente codice di accesso personalizzato (PIN)</p>
      <h4 class="text-center row-margin">{{ pin.pin }}</h4>
      <p class="text-center row-margin" *ngIf="resultDays > 1; else resultDaysOneDay">valido per {{resultDays}} giorni
        che puoi utilizzare per ricevere
        assistenza
        chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
      <ng-template #resultDaysOneDay>
        <p class="text-center row-margin">valido per {{resultDays}} giorno che puoi utilizzare per ricevere
          che puoi utilizzare per ricevere
          assistenza
          chiamando il Call Center al numero
          <span class="font-weight-bold">800767878</span>
        </p>
      </ng-template>
      <p class="text-center row-margin">selezionando
        <span class="font-weight-bold">0</span>
        dopo la fine del messaggio di benvenuto
        e digitando il PIN
        <span class="font-weight-bold">{{ pin.pin }}</span>
        verrai messo direttamente in contatto con l'operatore
        competente.
      </p>
      <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
      <ul>
        <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
        </li>
        <li> Il sabato, dalle ore 9:00 alle ore 12:00.
        </li>
      </ul>
    </div>
  </div>

  <!--Template flusso sms 009-->
  <ng-template #caso009Else>
    <div *ngIf="(competence === 'O' || competence === 'M') && problemCode === '009'; else caso004Else">
      <div *ngIf=" !isLoading && !errorStatus && !handleErrorMessage">
        <h3 class="text-center mb-3">Inoltre per ricevere assistenza per la targa</h3>
        <app-targa [targa]="targa"></app-targa>
        <p class="text-center row-margin"> annota il seguente codice di accesso personalizzato (PIN)</p>
        <h4 class="text-center row-margin">{{ pin.pin }}</h4>
        <p class="text-center row-margin" *ngIf="resultDays > 1; else resultDaysOneDay">valido per {{resultDays}} giorni
          che puoi utilizzare per ricevere
          assistenza
          chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
        <ng-template #resultDaysOneDay>
          <p class="text-center row-margin">valido per {{resultDays}} giorno che puoi utilizzare per ricevere
            che puoi utilizzare per ricevere
            assistenza
            chiamando il Call Center al numero
            <span class="font-weight-bold">800767878</span>
          </p>
        </ng-template>
        <p class="text-center row-margin">selezionando
          <span class="font-weight-bold">0</span>
          dopo la fine del messaggio di benvenuto
          e digitando il PIN
          <span class="font-weight-bold">{{ pin.pin }}</span>
          verrai messo direttamente in contatto con l'operatore
          competente.
        </p>
        <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
        <ul>
          <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
          </li>
          <li> Il sabato, dalle ore 9:00 alle ore 12:00.
          </li>
        </ul>
      </div>
    </div>
  </ng-template>

  <!--Template flusso sms 004-->
  <ng-template #caso004Else>
    <div *ngIf="(competence === 'O' || competence === 'M') && problemCode === '004'; else caso007Else">
      <div *ngIf=" !isLoading && !errorStatus && !handleErrorMessage">
        <h3 class="text-center mb-3">Per avere un accesso diretto agli operatori del Call Center, sia per informazioni
          che per assistenza per la targa</h3>
        <app-targa [targa]="targa"></app-targa>
        <p class="text-center row-margin"> annota il seguente codice di accesso personalizzato (PIN)</p>
        <h4 class="text-center row-margin">{{ pin.pin }}</h4>
        <p class="text-center row-margin" *ngIf="resultDays > 1; else resultDaysOneDay">valido per {{resultDays}} giorni
          che puoi utilizzare per ricevere
          assistenza
          chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
        <ng-template #resultDaysOneDay>
          <p class="text-center row-margin">valido per {{resultDays}} giorno che puoi utilizzare per ricevere
            che puoi utilizzare per ricevere
            assistenza
            chiamando il Call Center al numero
            <span class="font-weight-bold">800767878</span>
          </p>
        </ng-template>
        <p class="text-center row-margin">selezionando
          <span class="font-weight-bold">0</span>
          dopo la fine del messaggio di benvenuto
          e digitando il PIN
          <span class="font-weight-bold">{{ pin.pin }}</span>
          verrai messo direttamente in contatto con l'operatore
          competente.
        </p>
        <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
        <ul>
          <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
          </li>
          <li> Il sabato, dalle ore 9:00 alle ore 12:00.
          </li>
        </ul>
      </div>
    </div>
  </ng-template>

  <ng-template #caso007Else>
    <div *ngIf="(competence === 'O' || competence === 'M') && problemCode === '007'; else caso017Else">
      <div *ngIf=" !isLoading && !errorStatus && !handleErrorMessage">
        <h3 class="text-center mb-3">Per avere un accesso diretto agli operatori del Call Center, sia per informazioni
          che per assistenza per la targa</h3>
        <app-targa [targa]="targa"></app-targa>
        <p class="text-center row-margin"> annota il seguente codice di accesso personalizzato (PIN)</p>
        <h4 class="text-center row-margin">{{ pin.pin }}</h4>
        <p class="text-center row-margin" *ngIf="resultDays > 1; else resultDaysOneDay">valido per {{resultDays}} giorni
          che puoi utilizzare per ricevere
          assistenza
          chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
        <ng-template #resultDaysOneDay>
          <p class="text-center row-margin">valido per {{resultDays}} giorno che puoi utilizzare per ricevere
            che puoi utilizzare per ricevere
            assistenza
            chiamando il Call Center al numero
            <span class="font-weight-bold">800767878</span>
          </p>
        </ng-template>
        <p class="text-center row-margin">selezionando
          <span class="font-weight-bold">0</span>
          dopo la fine del messaggio di benvenuto
          e digitando il PIN
          <span class="font-weight-bold">{{ pin.pin }}</span>
          verrai messo direttamente in contatto con l'operatore
          competente.
        </p>
        <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
        <ul>
          <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
          </li>
          <li> Il sabato, dalle ore 9:00 alle ore 12:00.
          </li>
        </ul>
      </div>
    </div>
  </ng-template>


  <!--Template flusso sms 017-->
  <ng-template #caso017Else>
    <div *ngIf="(competence === 'O' || competence === 'M') && problemCode === '017'; else caso016Else">
      <div *ngIf=" !isLoading && !errorStatus && !handleErrorMessage">
        <h3 class="text-center mb-3">Per avere un accesso diretto agli operatori del Call Center, sia per informazioni
          che per assistenza per la targa</h3>
        <app-targa [targa]="targa"></app-targa>
        <p class="text-center row-margin"> annota il seguente codice di accesso personalizzato (PIN)</p>
        <h4 class="text-center row-margin">{{ pin.pin }}</h4>
        <p class="text-center row-margin" *ngIf="resultDays > 1; else resultDaysOneDay">valido per {{resultDays}} giorni
          che puoi utilizzare per ricevere
          assistenza
          chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
        <ng-template #resultDaysOneDay>
          <p class="text-center row-margin">valido per {{resultDays}} giorno che puoi utilizzare per ricevere
            che puoi utilizzare per ricevere
            assistenza
            chiamando il Call Center al numero
            <span class="font-weight-bold">800767878</span>
          </p>
        </ng-template>
        <p class="text-center row-margin">selezionando
          <span class="font-weight-bold">0</span>
          dopo la fine del messaggio di benvenuto
          e digitando il PIN
          <span class="font-weight-bold">{{ pin.pin }}</span>
          verrai messo direttamente in contatto con l'operatore
          competente.
        </p>
        <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
        <ul>
          <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
          </li>
          <li> Il sabato, dalle ore 9:00 alle ore 12:00.
          </li>
        </ul>
      </div>
    </div>
  </ng-template>


   <!--Template flusso sms 016-->
   <ng-template #caso016Else>
    <div *ngIf="(competence === 'O' || competence === 'M') && problemCode === '016'; else competenzaDElse">
      <div *ngIf=" !isLoading && !errorStatus && !handleErrorMessage">
        <h3 class="text-center mb-3">Per avere un accesso diretto agli operatori del Call Center, sia per informazioni
          che per assistenza per la targa</h3>
        <app-targa [targa]="targa"></app-targa>
        <p class="text-center row-margin"> annota il seguente codice di accesso personalizzato (PIN)</p>
        <h4 class="text-center row-margin">{{ pin.pin }}</h4>
        <p class="text-center row-margin" *ngIf="resultDays > 1; else resultDaysOneDay">valido per {{resultDays}} giorni
          che puoi utilizzare per ricevere
          assistenza
          chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
        <ng-template #resultDaysOneDay>
          <p class="text-center row-margin">valido per {{resultDays}} giorno che puoi utilizzare per ricevere
            che puoi utilizzare per ricevere
            assistenza
            chiamando il Call Center al numero
            <span class="font-weight-bold">800767878</span>
          </p>
        </ng-template>
        <p class="text-center row-margin">selezionando
          <span class="font-weight-bold">0</span>
          dopo la fine del messaggio di benvenuto
          e digitando il PIN
          <span class="font-weight-bold">{{ pin.pin }}</span>
          verrai messo direttamente in contatto con l'operatore
          competente.
        </p>
        <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
        <ul>
          <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
          </li>
          <li> Il sabato, dalle ore 9:00 alle ore 12:00.
          </li>
        </ul>
      </div>
    </div>
  </ng-template>

  <!--Template flusso generico competenza "Decorrenza futura"-->
  <ng-template #competenzaDElse>
    <div *ngIf="competence === 'D' && problemCode === '000'; else competenzaIElse">
      <div *ngIf="!isLoading && !errorStatus && !handleErrorMessage">
        <h3 class="text-center mb-3">Il contratto associato alla targa</h3>
        <app-targa [targa]="targa"></app-targa>
        <p class="text-center mb-3">decorre dal
          <span class="font-weight-bold">{{ insuranceStartDate | date: 'dd/MM/yyyy' }}</span>, 
          sino a tale data non sono disponibili tutte le informazioni relative al tuo contratto,
          ma se lo desideri puoi consultare le risposte alle domande più frequenti (FAQ) selezionando "Procedi".
        </p>
        <p class="text-center mb-3">
          Ti preghiamo di annotare il seguente codice di accesso personalizzato (PIN)
        </p>
        <h4 class="text-center row-margin">{{ pin.pin }}</h4>
        <p class="text-center row-margin" *ngIf="resultDays > 1">
          valido per {{resultDays}} giorni
          che puoi utilizzare per ricevere
          assistenza
          chiamando in data successiva
          <span class="font-weight-bold">{{ insuranceStartDate | date: 'dd/MM/yyyy' }}</span>
          al Call Center al numero
          <span class="font-weight-bold">800767878</span>
        </p>
        <p class="text-center row-margin">
          selezionando <span class="font-weight-bold">0</span> e digitando il PIN
          <span class="font-weight-bold">{{ pin.pin }}</span>
          verrai messo direttamente in contatto con l'operatore
          competente.</p>
        <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
        <ul>
          <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
          </li>
          <li> Il sabato, dalle ore 9:00 alle ore 12:00.
          </li>
        </ul>
      </div>
    </div>
  </ng-template>

  <!--Template flusso generico competenza "Indeterminata"-->
  <ng-template #competenzaIElse>
    <div *ngIf="competence === 'I' && problemCode === '000'; else caso009Else">
      <div *ngIf="!isLoading && !errorStatus && !handleErrorMessage">
        <h3 class="text-center mb-3">La Targa</h3>
        <app-targa [targa]="targa"></app-targa>
        <p class="text-center">non risulta registrata nei sistemi del gruppo Unipol,
          pertanto non è possibile fornire risposte specifiche alle tue richieste di assistenza:
        </p>
        <ul>
          <li>Se vuoi correggere la targa, seleziona "Home".</li>
          <li>Se vuoi consultare le risposte alle domande più frequenti (FAQ), seleziona "Procedi".</li>
        </ul>
        <p>
          oppure puoi annotare il seguente codice di accesso personalizzato (PIN) </p>
        <h4 class="text-center row-margin">{{ pin.pin }}</h4>
        <p class="text-center row-margin" *ngIf="resultDays > 1">valido per {{resultDays}} giorni
          che puoi utilizzare per ricevere
          assistenza
          chiamando il Call Center al numero
          <span class="font-weight-bold">800767878</span>
        </p>
        <p class="text-center row-margin">selezionando
          <span class="font-weight-bold">0</span>
          al termine del messaggio di benvenuto e digitando il PIN
          verrai messo direttamente in contatto con l'operatore
          competente.
        </p>
        <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
        <ul>
          <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
          </li>
          <li> Il sabato, dalle ore 9:00 alle ore 12:00.
          </li>
        </ul>
      </div>
    </div>
  </ng-template>

  <div class="mu-error-message-container" *ngIf="handleErrorMessage">
    <!-- <i class="fa fa-exclamation-circle fa-5x text-danger mb-5"></i> -->
    <fa-icon [icon]="faExclamationCircle" class="fa-5x text-danger mb-5"></fa-icon>

    <div class="mu-error-message">
      <h3 class="text-danger">Si è verificato un errore!</h3>
      <p class="text-danger  text-weight-bold mb-5">{{handleErrorMessage}}</p>
      <p class="text-danger">Si prega di riprovare più tardi.</p>
    </div>
  </div>
</ng-template>