<app-contract-link [targa]="a" [companyId]="b" [anomalyCode]="d" *ngIf="this.pin != null"></app-contract-link>
<div class="text-center" *ngIf="!isError && pin?.codeMessage !== 'DEVICE_OK' && pin != null">
  <h3 class="mb-4">
    Hai ricevuto un SMS che ti segnala che il dispositivo telematico installato sul tuo veicolo non risulta ancora attivo,
    potrebbe essere quindi non ancora installato o non funzionante.
  </h3>

  <h3 class="mb-4">
    Per verificare se è necessario eseguire un intervento di diagnosi presso un'officina convenzionata, ti chiediamo di
    rispondere ad alcune semplici domande: clicca su "<b>Procedi</b>" in fondo alla pagina.
  </h3>
</div>

<div *ngIf="pin?.codeMessage === 'DEVICE_OK'; else otherCodeMessage">
  <app-device-ok-template [pin]="pin" [targa]="a"></app-device-ok-template>
</div>

<ng-template #otherCodeMessage>
  <app-pin [targa]="a" [companyId]="b" [imeiCode]="c" [problemCode]="d" (pinEvent)="onPinEvent($event)" (errorEvent)="onErrorEvent()">
  </app-pin>
</ng-template>

<div class="margin-bottom" *ngIf="!isError && pin?.codeMessage !== 'DEVICE_OK' && pin != null">
  <div class="text-center mu-fixed-btn-container">
    <button class="btn mu-btn btn-primary" (click)="onProcedi()">Procedi</button>
  </div>
</div>