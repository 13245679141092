import { FaqLivello1, FaqLivello2 } from './../../models/faq-generico.model';
import { FaqService } from './../../services/faq.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router,NavigationStart } from '@angular/router';
import { PinsService } from '../../services/pins.service';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ErrorMeta } from 'src/app/models/errorMeta.model';
import { ErrorsService } from 'src/app/services/errors.service';
import { Location } from '@angular/common'
import { ContractInfo } from 'src/app/models/contract-info.model';
import {DatePipe} from '@angular/common';
import { KpiLogsService, KpiLogEvent } from 'src/app/services/kpi-logs.service';

@Component({
  selector: 'app-contract-page',
  templateUrl: './contract-page.component.html',
  styleUrls: ['./contract-page.component.scss']
})
export class ContractPageComponent implements OnInit {

  isLoading = true;
  displayFull = true;
  //errorStatus: string = null;
  //errorMessage: string = null;
  //handleErrorMessage: string = null;
  //faExclamationCircle = faExclamationCircle;

  NOT_AVAILABLE: string = 'Informazione non disponibile al momento';

  targa: string;
  companyId: string;
  contractInfo: ContractInfo;
  anomalyCode: string;
  today: Date = new Date();
  
  constructor(
    private route: ActivatedRoute,
    private pinsService: PinsService,
    private router: Router,
    private location: Location,
    private datePipe: DatePipe,
    private kpiLogsService: KpiLogsService,){ 
}
//  ET441MD
//  AM034NK
  ngOnDestroy() {
  }
 
  ngOnInit() {
    this.isLoading = true;
    this.route.queryParams.subscribe(async p => {
      this.targa = p.targa;
      this.companyId = p.companyId;
      this.anomalyCode = p.anomalyCode;
      console.log(' + ngOnInit() --> this.targa --> ', this.targa);
      console.log(' + ngOnInit() --> this.companyId --> ', this.companyId);

      this.displayFull = this.companyId != null && this.companyId.length > 0;

      this.pinsService.getContractInfo(this.targa, this.companyId).subscribe(        
        response => {
          console.log('raw response --> ', response);

          this.contractInfo = response;

          // fix data
          this.contractInfo.telematicContractSummary.status = Number(this.contractInfo.telematicContractSummary.status);
          if(this.contractInfo.telematicContractSummary.contractStartDate)
            this.contractInfo.telematicContractSummary.contractStartDate = new Date(this.contractInfo.telematicContractSummary.contractStartDate);
          
          if(this.contractInfo.telematicContractSummary.contractEndDate)
            this.contractInfo.telematicContractSummary.contractEndDate = new Date(this.contractInfo.telematicContractSummary.contractEndDate);
        
        /* TEST
        this.contractInfo.telematicContractSummary.status = 0;
        this.contractInfo.telematicContractSummary.contractStartDate = new Date();  
        this.contractInfo.telematicContractSummary.contractStartDate.setDate(this.contractInfo.telematicContractSummary.contractStartDate.getDate() - 2);
        this.contractInfo.telematicContractSummary.contractEndDate = new Date();  
        this.contractInfo.telematicContractSummary.contractEndDate.setDate(this.contractInfo.telematicContractSummary.contractEndDate.getDate() + 3);
        */

          if(this.contractInfo.telematicContractSummary.dossiers && this.contractInfo.telematicContractSummary.dossiers.length > 0){
            this.contractInfo.telematicContractSummary.dossiers.forEach(d => {              
              if(d.dossierOpenDate) d.dossierOpenDate = new Date(d.dossierOpenDate);
              if(d.dossierCloseDate) d.dossierCloseDate = new Date(d.dossierCloseDate);
            });
          }

          console.log('patched response --> ', response);

          let data = {
            vehiclePlate: this.targa
          };
          if(this.anomalyCode && this.anomalyCode != '000')
            data['notes'] = JSON.stringify({anomalyCode: this.anomalyCode});

          this.kpiLogsService.registerKPILogs(this.displayFull ? KpiLogEvent.CTRL_PANEL_SMS : KpiLogEvent.CTRL_PANEL_GENERIC, data);
          this.isLoading = false;
        },
        error => {
          console.log(' [ContractPageComponent] + get info: error --> ', error);
          this.router.navigate(['error']);
          this.isLoading = false;
        }
      );
    });
  }

  onBack(): void {
    this.location.back();
  }

  // DECODERS

  getContractStaus(){
    
    if(this.contractInfo.telematicContractSummary.contractStartDate
        && this.contractInfo.telematicContractSummary.status == 0 
        && this.contractInfo.telematicContractSummary.contractStartDate > this.today){
        return "Il contratto non è ancora attivo: attendere";
    }

    if(this.contractInfo.telematicContractSummary.contractEndDate){
      if(this.displayFull){
        if(this.contractInfo.telematicContractSummary.status == 0){
          let stats = ['Attivo', 'Attesa rinnovo o chiusura'];
          return this.contractInfo.telematicContractSummary.contractEndDate >= this.today ? stats[0] : stats[1];
        }
        else{
          let stats = ['Chiuso', 'In chiusura', 'Il contratto non è ancora attivo: attendere', 'Sospeso'];
          return stats[this.contractInfo.telematicContractSummary.status-1];
        }
      }
      else{
        if(this.contractInfo.telematicContractSummary.status == 0){
          let stats = ['Regolare', 'Sospeso'];
          return this.contractInfo.telematicContractSummary.contractEndDate >= this.today ? stats[0] : stats[1];
        }
        else{
          let stats = ['Chiuso', 'Da verificare', 'Il contratto non è ancora attivo: attendere', 'Sospeso'];
          return stats[this.contractInfo.telematicContractSummary.status-1];
        }
      }
    }
    return this.NOT_AVAILABLE;
  }
  getContractStartDate(){
    if(this.contractInfo.telematicContractSummary.contractStartDate && this.contractInfo.telematicContractSummary.contractEndDate){
      if(this.displayFull){
        if(this.contractInfo.telematicContractSummary.status == 4)
          return "Contratto Sospeso";
        else if(this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractEndDate < this.today)
          return "Attesa rinnovo o chiusura";
        else {
          let ret = this.datePipe.transform(this.contractInfo.telematicContractSummary.contractStartDate,"dd/MM/yyyy");
          if(this.contractInfo.telematicContractSummary.contractStartDate > this.today)
            ret += " - Inizio decorrenza futura";
          return ret;
        }
      }
      else{
        //none
      }
    }
    return this.NOT_AVAILABLE;
  }
  getContractEndDate(){
    if(this.contractInfo.telematicContractSummary.contractEndDate){
      if(this.displayFull){
        if(this.contractInfo.telematicContractSummary.status == 4)
          return "Contratto Sospeso";
        else if(this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractEndDate < this.today)
          return "Attesa rinnovo o chiusura";
        else{
          let ret = this.datePipe.transform(this.contractInfo.telematicContractSummary.contractEndDate,"dd/MM/yyyy");          
          if(this.contractInfo.telematicContractSummary.contractEndDate < this.today)
            ret += " - Validità terminata";
            return ret;
        }
      }
      else{
        //none
      }
    }
    return this.NOT_AVAILABLE;
  }
  
  getContractValidity(){
    if(this.contractInfo.telematicContractSummary.contractStartDate && this.contractInfo.telematicContractSummary.contractEndDate){
      if(this.displayFull){
        // none
      }
      else{
        
        if(this.contractInfo.telematicContractSummary.status == 4)
          return "Contratto Sospeso";

        if(this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractEndDate < this.today)
          return "Attesa rinnovo o chiusura";

        if(this.contractInfo.telematicContractSummary.contractStartDate > this.today)
          return "Decorrenza validità futura";
        
        if(this.contractInfo.telematicContractSummary.contractEndDate < this.today)
          return "Validità terminata";

        return "In corso di validità";
      }
    }
    return this.NOT_AVAILABLE;
  }


  getSalesChannel(){
    
    if(this.contractInfo.telematicContractSummary.status == 3 /*|| 
      (this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractStartDate > this.today)*/){
      return "";
    }

    if(this.contractInfo.telematicContractSummary.salesChannel != null){
      let chans = ['Agenzia', 'vs Assicuratore', 'vs Assicuratore', 'vs Assicuratore', 'Agenzia']
      return chans[this.contractInfo.telematicContractSummary.salesChannel];
    }
    return this.NOT_AVAILABLE;
  }
  
  getSalesAgency(){
    if(this.contractInfo.telematicContractSummary.agencyId != null){
      return this.contractInfo.telematicContractSummary.agencyId;
    }
    return this.NOT_AVAILABLE;
  }
  
  getInstaller(){
    if(this.contractInfo.installerInfo != null){
      let desc = this.contractInfo.installerInfo.ragioneSociale;
      if(this.contractInfo.installerInfo.numeroTelefono)
        desc += " e numero di telefono: " + this.contractInfo.installerInfo.numeroTelefono;
      return desc;
    }
    return "Dato non disponibile";
  }

/*
  getInstallerName(){
    if(this.contractInfo.installerInfo != null){
      return this.contractInfo.installerInfo.ragioneSociale;
    }
    return this.NOT_AVAILABLE;
  }
  getInstallerPhone(){
    if(this.contractInfo.installerInfo != null){
      return this.contractInfo.installerInfo.numeroTelefono;
    }
    return '';
  }
*/
  getDeviceType(){
    if(this.contractInfo.telematicContractSummary.telematicDevice.deviceType != null){
      let decoder = {
        F : "Per Auto con installazione in officina",
        T : "Per Auto con installazione in officina e servizi Top",
        M : "Per Moto con installazione in officina",
        E : "(E) non più disponibile",
        S : "Per Auto con installazione Assicurato",
        B : "(B) non più disponibile",
        V : "Per Auto con installazione in officina e accessorio voce",
        U : "Per Moto con installazione Assicurato",
        A : "Per Auto con installazione in officina e accessorio voce",
        C : "Per Auto con installazione Assicurato",
      }
      if(decoder[this.contractInfo.telematicContractSummary.telematicDevice.deviceType] != null)
        return decoder[this.contractInfo.telematicContractSummary.telematicDevice.deviceType];
    }
    return this.NOT_AVAILABLE;
  }

  getIMEI(){
    if(this.contractInfo.telematicContractSummary.telematicDevice.imei != null){
      if(!(new RegExp('^[0]*$').test(this.contractInfo.telematicContractSummary.telematicDevice.imei))){
        return this.contractInfo.telematicContractSummary.telematicDevice.imei;
      }
    }
    return null;
  }

  shouldDisplayAnomalySection(){
    let ret = false;
    let dossier = this.getActiveDossier();
    if(dossier != null  && dossier.dossierCloseDate == null
        && (dossier.dossierType == 1 ||dossier.dossierType == 2) 
        && this.getDeviceStatus() != null 
        && this.contractInfo.telematicContractSummary.status != 1)
      ret = true;

      if(this.contractInfo.telematicContractSummary.status == 0 
        && this.contractInfo.telematicContractSummary.contractEndDate >= this.today 
        && this.contractInfo.telematicContractSummary.telematicDevice.deviceStatus != null){        
      
        if(this.contractInfo.telematicContractSummary.telematicDevice.deviceStatus.toLowerCase() == 'active')
          if(!dossier || (dossier.dossierType != 1 && dossier.dossierType != 2))
            ret = false;
      }

    return ret;
  }

  activeDossier = null;
  getActiveDossier(){
    if(!this.activeDossier){
      if(this.contractInfo.telematicContractSummary.dossiers && this.contractInfo.telematicContractSummary.dossiers.length){
        for(let d of this.contractInfo.telematicContractSummary.dossiers){
          if(d.dossierType != 0 && d.dossierOpenDate != null){
            if(d.dossierCloseDate == null){
              if(this.activeDossier == null || d.dossierType == 1 || d.dossierCloseDate != null)
                this.activeDossier = d;
            } 
            else{
              if(this.activeDossier == null)
                this.activeDossier = d;
            }
          }
        }
      }
    }
    return this.activeDossier;
  }
  
  getDeviceStatus(){
    if(this.contractInfo.telematicContractSummary.status == 0 && this.contractInfo.telematicContractSummary.contractEndDate >= this.today && this.contractInfo.telematicContractSummary.telematicDevice.deviceStatus != null){        
      
      let dossier = this.getActiveDossier();
      switch(this.contractInfo.telematicContractSummary.telematicDevice.deviceStatus.toLowerCase()){
        case "active":
          if(dossier && dossier.dossierCloseDate == null){
            if(dossier.dossierType == 1)
              return "Rilevata possibile anomalia, in corso di verifica";
            else if(dossier.dossierType == 2)
              return "Analisi possibile anomalia in corso di verifica";
          }
          return "Funzionamento regolare";
          
        case "notactive":
        case "not active":
          return "Non installato sul veicolo";
          
        case "notlinked":
        case "not linked":
          return "Non ancora registrato";
          
        //case "theft": non gestisco, ha default message

        case "anomaly":
          if(dossier && dossier.dossierCloseDate == null)
            return "Analisi possibile anomalia in corso di verifica";
      }
    }      
    return null;
  }
  
  getAnomalyState(){
    let dossier = this.getActiveDossier();
    if(dossier && dossier.dossierCloseDate == null){
      let decode = {
        1: "Anomalia in corso di verifica",
        2: "Anomalia in corso di analisi"
      };
      return decode[dossier.dossierType] || this.NOT_AVAILABLE;
    }
    return this.NOT_AVAILABLE;
  }

  getAnomalyType(){
    //if(this.contractInfo.jiraDossierData){ 
    let dossier = this.getActiveDossier();
    if(dossier && dossier.dossierCloseDate == null){
      let decode = {
        1: "1-Registrazione posizione",
        2: "2-Rilevazione spostamento",
        3: "3-Rilevazione spostamento",
        4: "4-Frequenza comunicazione",
        5: "5-Frequenza comunicazione",
        6: "6-Stato alimentazione",
        7: "7-Rilevamento accelerazione",
        8: "8-Rilevamento accelerazione",
        9: "SUEB".indexOf(this.contractInfo.telematicContractSummary.telematicDevice.deviceType) >= 0 ? "9a-Installazione non rilevata" : "9b-Installazione non completata",
        10: "10-Generica",
        11: "11-Anomalia tecnica",
        12: "12-Anomalia tecnica",
        13: "13-Rilevazione posizione",
        14: "14-Anomalia tecnica",
        16: this.displayFull ? "16-Plancetta solar NON comunicante" : "16- Anomalia Tecnica",
        17: this.displayFull ? "17-Plancetta Solar NON attivata" : "17-Anomalia Tecnica",
        20: this.displayFull ? "20-Anomalia di migrazione" : "20-Anomalia Tecnica",
        25: this.displayFull ? "25-Evento registrato da installatore" : "25-Anomalia in Verifica veicolo in officina",
        26: this.displayFull ? "26-Evento registrato da Ops Alfa" : "26-Anomalia in Verifica"
      };
      //const idx = Number(this.contractInfo.jiraDossierData.dossierAnomalyCode);
      const idx = Number(dossier.anomalyId);
      if(!isNaN(idx) && decode[idx])
        return decode[idx];
    }
    return this.NOT_AVAILABLE;
  }

}
