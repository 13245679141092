<!-- <app-alert [status]="errorStatus" [message]="errorMessage" *ngIf="errorStatus" (closeAlert)="onCloseAlert()">
</app-alert> -->

<app-loading-spinner *ngIf="isLoading; else contentBlock"></app-loading-spinner>

<ng-template #contentBlock>
  <app-contract-link [targa]="a" [companyId]="b" [anomalyCode]="'004'"></app-contract-link>
  <h3 class="text-center mb-2">Per le cinque domande qui elencate, seleziona Si o NO; 
    puoi aggiungere altre informazioni nel campo “note” in fondo alla pagina, poi clicca su
    "<b>REGISTRA</b>" per inviarci la risposta:</h3>

  <!-- <app-targa [targa]="a"></app-targa> -->

  <!-- {{currentIndex |json}} -->
  <form class="text-center" #radioForm="ngForm" (submit)="onRegistra($event)">
    <ul class="mu-list-group list-group list-group-flush text-left mb-5">
      <li class="list-group-item mu-list-group-item mu-list-group-item-2nd-level"
        *ngFor="let faq of faqTree?.faq; let i = index">

        <div>
          <span class="mu-list-group-item-text mu-list-group-item-radio-text">
            <div [innerHtml]="faq.testo"></div>
          </span>
          <div class="mu-list-group-item-radio-group">
            <div class="form-check form-check-inline">
              <input [attr.id]="'radioOK' + i" class="form-check-input" [name]="faq.nome" ng-control="options"
                type="radio" [value]="1" [(ngModel)]="faq.opzioneUtente" (click)="onClickOptions(faq, 'yes')" required>
              <label class="form-check-label" [attr.for]="'radioOK' + i">Si</label>
            </div>

            <div class="form-check form-check-inline mr-0">
              <input [attr.id]="'radioKO' + i" class="form-check-input" [name]="faq.nome" ng-control="options"
                type="radio" [value]="0" [(ngModel)]="faq.opzioneUtente" (click)="onClickOptions(faq, 'no')" required>
              <label class="form-check-label" [attr.for]="'radioKO' + i">No</label>
            </div>
          </div>

          <div class="mu-list-group-item-description" *ngIf="faq.isVisible && faq.opzioneUtente; else faqRispostaNo">
            <!-- <p>{{ faq.SI.risposta }}</p> -->
            <div [innerHtml]="faq.SI.risposta"></div>
          </div>

          <ng-template #faqRispostaNo>
            <div class="mu-list-group-item-description" *ngIf="faq.isVisible && !faq.opzioneUtente">
              <!-- <p>{{ faq.NO.risposta }}</p> -->
              <div [innerHtml]="faq.NO.risposta"></div>
            </div>
          </ng-template>
        </div>
      </li>
    </ul>

    <div class="text-margin-bottom" *ngIf="faqTree">
      <textarea class="mu-form-control mu-textarea form-control" rows="4" id="note" name="note" placeholder="Note..."
        [(ngModel)]=" faqTree.note">
    </textarea>

      <div class="form-check text-left" *ngIf="pin?.hasEmail">
        <input class="form-check-input" type="checkbox" id="email" name="email" [(ngModel)]="faqTree.email">
        <label class="form-check-label" for="email">
          Desideri continuare questo dialogo via email?
        </label>
      </div>
    </div>

    <div class="text-center mu-fixed-btn-container">
      <button class="mu-btn btn btn-primary" [disabled]="radioForm.invalid" type="submit">Registra e Procedi</button>
    </div>
  </form>
</ng-template>