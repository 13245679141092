import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqCaseComponent } from 'src/app/core/faq-case-component';
import { ErrorsService } from 'src/app/services/errors.service';
import { FaqService } from 'src/app/services/faq.service';
import { PinsService } from 'src/app/services/pins.service';

@Component({
  selector: 'app-faq-case007',
  templateUrl: './faq-case007.component.html',
  styleUrls: ['./faq-case007.component.scss']
})
export class FaqCase007Component extends FaqCaseComponent implements OnInit {

  constructor(
    route: ActivatedRoute,
    router: Router,
    faqService: FaqService,
    pinsService: PinsService,
    errorsService: ErrorsService

  ) {

    super(route, router, faqService, pinsService, errorsService)
  }

  ngOnInit() {

    super.ngOnInit()
  }

}
