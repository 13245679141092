import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Pin } from '../../models/pin.model';
import { ActivatedRoute, Router ,NavigationStart ,NavigationEnd} from '@angular/router';


@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sms-page-case004',
  templateUrl: './sms-page-case004.component.html',
  styleUrls: ['./sms-page-case004.component.scss']
})
export class SmsPageCase004Component implements OnInit {

  a: string = null;
  b: string = null;
  c: string = null;
  d: string = null;
  pin: Pin = null;
  isError = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,

  ) { }




  ngOnInit() {
    //console.log('  [SmsPageCase004Component] this.pin.codeMessage + p --> ', this.pin.codeMessage);
    this.route.queryParams.subscribe(p => {
      console.log(' + p --> ', p);

      this.a = p.a;
      this.b = p.b;
      this.c = p.c;
      this.d = p.d;


      console.log(' [SmsPageCase004Component] + ngOnInit() --> this.a (targa)--> ', this.a);
      console.log(' [SmsPageCase004Component] + ngOnInit() --> this.b (companyId)--> ', this.b);
      console.log(' [SmsPageCase004Component] + ngOnInit() --> this.c (imeiCode)--> ', this.c);
      console.log(' [SmsPageCase004Component] + ngOnInit() --> this.d (problemCode)--> ', this.d);
    });


  }

  onPinEvent(pin: Pin) {
    console.log(' [SmsPageCase004Component] + onPinEvent() --> pin 1 --> ', pin);
    console.log('  [SmsPageCase004Component] this.pin.codeMessage + pin 1 --> ', pin.codeMessage);

    this.pin = pin;
    console.log('  [SmsPageCase004Component] this.pin.codeMessage + 2 --> ', pin.codeMessage);
    console.log('  [SmsPageCase004Component] this.isError --> ', this.isError);



  }

  onErrorEvent() {
    this.isError = true;
  }

  onProcedi() {
    this.router.navigate(
      ['faq-case004'],
      {
        queryParams: {
          p: this.pin.pin,
          b: this.b,
          created: new Date(this.pin.created).getTime(),
          expirationDate: new Date(this.pin.expirationDate).getTime()
        },
        queryParamsHandling: 'merge'
      }
    );
  }
}
