import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  public title: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.title = this.route.snapshot.data.title;
  }

  onBackHome() {
    //this.router.navigate(['/home']);
    this.router.navigate(['/home'], { queryParams: { ts: new Date().valueOf() } });

  }

}
