import { ErrorMeta } from 'src/app/models/errorMeta.model';
import * as i0 from "@angular/core";
export class ErrorsService {
    constructor() { }
    getErrorParse(errorCode) {
        switch (errorCode) {
            case 404:
                const error404 = new ErrorMeta(errorCode, 'Not Found', "La targa non risulta presente nei sistemi.");
                return error404;
            case 422:
                const error422 = new ErrorMeta(errorCode, 'Unprocessable Entity', 'Sono presenti dei campi mancanti o con errori');
                return error422;
            case 500:
                const error500 = new ErrorMeta(errorCode, 'Internal Server Error', 'Si è verificato un errore interno del server');
                return error500;
            case 503:
                const error503 = new ErrorMeta(errorCode, 'Service Unavailable', 'I sistemi al momento non sono disponibili, si prega di riprovare più tardi');
                return error503;
            default:
                const genericError = new ErrorMeta(errorCode, 'Unknown Error', 'Errore sconosciuto');
                return genericError;
        }
    }
}
ErrorsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorsService_Factory() { return new ErrorsService(); }, token: ErrorsService, providedIn: "root" });
