import { Component, OnInit,OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sms-flow-end',
  templateUrl: './sms-flow-end.component.html',
  styleUrls: ['./sms-flow-end.component.scss']
})
export class SmsFlowEndComponent implements OnInit,OnDestroy {

  routerSubscription: Subscription;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router) {  }

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
            console.log('[SmsFlowEndComponent] popstate (BACK ?? ) ', event.navigationTrigger);

            this.router.navigate(['sms-flow-end'], { replaceUrl: true });
            this.location.replaceState(this.router.serializeUrl(this.router.createUrlTree(['sms-flow-end'])));

         }
    });

  }
  ngOnDestroy() {
    if(this.routerSubscription){
      this.routerSubscription.unsubscribe();
    }
  }
}
