import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SmsPageCaseComponent } from 'src/app/core/sms-page-case-component';
import { Pin } from 'src/app/models/pin.model';

@Component({
  selector: 'app-sms-page-case007',
  templateUrl: './sms-page-case007.component.html',
  styleUrls: ['./sms-page-case007.component.scss']
})
export class SmsPageCase007Component extends SmsPageCaseComponent implements OnInit {

  constructor(
     route: ActivatedRoute,
     router: Router
  ) { 
    super(route,router,'faq-case007')
  }

  ngOnInit() {
    super.ngOnInit()
  }


  onPinEvent(pin: Pin) {
    console.log(`onPinEvent [${this.name}]` )
    super.onPinEvent(pin)

  }

}
