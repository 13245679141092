import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Pin } from '../models/pin.model';
import { ContractInfo } from '../models/contract-info.model';
import { SMSDetails } from '../models/sms-details.model';

@Injectable({
  providedIn: 'root'
})
export class PinsService {

  base_url: string = environment.base_url;

  constructor(private http: HttpClient) { }

  getPinsFlussoGenerico(targa: string, pin: number) {

    return this.http.get<Pin>(
      this.base_url + '/genericflow/' + 'pins' + '?vehiclePlate=' + targa + '&pin=' + pin
    );
  }

  getPinsSms(targa: string, pin: number) {

    return this.http.get<Pin>(
      this.base_url + '/smsflow/' + 'pins' + '?vehiclePlate=' + targa + '&pin=' + pin
    );
  }

  createPinFlussoGenerico(targa: string, faqData: string) {

    const inputData = {
      vehiclePlate: targa,
    };

    return this.http.post<Pin>(
      this.base_url + '/genericflow/' + 'pins',
      inputData
    );
  }

  patchPinFlussoGenerico(id: number, data: any) {

    return this.http.patch(
      this.base_url + '/genericflow/' + 'pins/' + id,
      data
    );
  }

  getPinsFlussoSMS(a: string, p: number, d: string = '004' || '009') {
    return this.http.get<Pin[]>(
      `${this.base_url}/smsflow/pins?vehiclePlate=${a}&pin=${p}`
    );
  }

  createPinFlussoSMS(a: string, b: string = "U" || "L" || "A", c: string, d: string = '004' || '009') {

    const inputData = {
      vehiclePlate: a,
      companyId: b,
      imeiCode: c,
      anomalyCode: d
    };

    let url: string = '';

    url = url.concat(this.base_url, '/smsflow/pins');

    return this.http.post<Pin>(
      url, inputData
    );
  }

  patchPinFlussoSms(id: number, data: any) {
    console.log('[PinsService-> patchPinFlussoSms] --> ', ' id ', id, ' data ', data);

    return this.http.patch(
      this.base_url + '/smsflow/' + 'pins/' + id,
      data
    );
  }

  async pinData(targa: string, p: number, type: string = 'G' || 'S') {
    let pin: Pin;
    let pinData = null;
    try {
      if (type == 'G')
        pinData = await this.getPinsFlussoGenerico(targa, p).toPromise()
      else if (type == 'S')
        pinData = await this.getPinsFlussoSMS(targa, p).toPromise()

    }
    catch (error) {
      console.error(error);

      throw error;
    }

    console.log(' pinsService.getPinsFlussoGenerico(' + targa + ') --> ', pinData);


    pin = new Pin(
      null,
      targa,
      new Date(),
      new Date(),
      'M',
      null,
      null,
      'R',
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      new Date()
    )
    pin.pin = pinData.pin;
    pin.vehiclePlate = pinData.vehiclePlate;
    pin.created = pinData.created;
    pin.expirationDate = pinData.expirationDate;
    pin.competencePlate = pinData.competencePlate;
    pin.email = pinData.email;
    pin.deviceType = pinData.deviceType;
    pin.codeMessage = pinData.codeMessage;
    pin.hasEmail = pinData.hasEmail;
    pin.communicationType = pinData.deviceType;
    pin.otherInfo = pinData.otherInfo;
    pin.contractId = pinData.contractId;
    pin.insurancePolicy = pinData.insurancePolicy;
    pin.deviceStatus = pinData.deviceStatus;
    pin.insuranceStartDate = pinData.insuranceStartDate;
    return pin;
  }

  
  getContractInfo(plate: string, company?: string) {
    return this.http.get<ContractInfo>(
      `${this.base_url}/contractInfo?vehiclePlate=${plate}` + (company ? `&companyId=${company}` : '')
    );
  }

  
  getSMSDetails(smsId: string) {
    return this.http.get<SMSDetails>(
      `${this.base_url}/getSMSDetails?smsLog=${smsId}`
      //`http://localhost:3000/api/getSMSDetails?smsLog=${smsId}`
    );
  }
}