<main>
  <div class="container">
    <!-- <div [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div> -->

    <!-- <router-outlet></router-outlet> -->
    <div class="row">
      <div class="mu-column col-md-6 offset-md-3 p-md-5 py-5">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>

<app-footer></app-footer>