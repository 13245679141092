<div>
    <div>
        <h3 class="text-center mb-3">Siamo spiacenti, ma per motivi tecnici l'operazione non può essere al momento
            eseguita.</h3>
        <h3 class="text-center mb-3">Ti preghiamo di riprovare più tardi.</h3>
        <p class="text-center"> Nel frattempo puoi: </p>
        <ul>
            <li> Scriverci all'indirizzo <a href="mailto:customer@unipoltech.it">customer@unipoltech.it</a>, indicando
                la tua necessità e sarà nostra cura risponderti appena possibile.
            </li>
            <li> Chiamare il nostro Call Center al numero <b>800767878</b>, attivo nei seguenti orari:
                <ul>
                    <li>Da lunedì al
                        venerdì,
                        dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.</li>
                    <li> Il sabato, dalle ore 9:00 alle ore 12:00.</li>
                </ul>
            </li>
        </ul>
        <p class="text-center"> Ci scusiamo ancora per l'inconveniente tecnico e ti rinnoviamo l'invito a riprovare in
            seguito.</p>
    </div>
</div>