import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


export class KpiLogEvent{

  public static readonly SITE_ACCESS_EVENT:KpiLogEvent = new KpiLogEvent('genericflow_access', '00', false);
  public static readonly CTRL_PANEL_GENERIC:KpiLogEvent = new KpiLogEvent('ctrlpanel_generic_access', '09', true);
  public static readonly CTRL_PANEL_SMS:KpiLogEvent = new KpiLogEvent('ctrlpanel_sms_access', '10', true);

  private sent: number = 0;
  constructor(private actionId:string, private code:string, private onceXSession:boolean){
  }

  getActionId():string{
    return this.actionId;
  }

  getCode():string{
    return this.code;
  }

  couldSend():boolean{
    return this.onceXSession ? this.sent == 0 : true;
  }

  markSent(){
    this.sent++;
  }
}


@Injectable({
  providedIn: 'root'
})
export class KpiLogsService {
  base_url: string = environment.base_url;

  constructor(private http: HttpClient) { }

  registerKPILogs(event:KpiLogEvent, args:object = null) {
    console.log('KpiLogsService.registerKPILogs: %o', event);

    if(event.couldSend()){
          if(args){
            args['eventTimestamp'] = new Date();
            args['code'] = event.getCode();
          }
          else{
            args = {};
          }

          this.http.post<{
            id: number;
            pin: number;
            vehiclePlate: string;
            eventTimestamp: string;
            code: string;
            notes: string
          }>(
            this.base_url + '/kpi-logs/' + event.getActionId(),
            args
          ).subscribe(
            res => {
              console.log('KpiLogsService.registerKPILogs: sent => %o', res);
              event.markSent();   
            },
            err => {
              console.log('KpiLogsService.registerKPILogs: error => %o', err);
            });
    }
    else{
      console.log('KpiLogsService.registerKPILogs: event %s already sent', event.getActionId());
    }
  }
}
