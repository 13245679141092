<app-contract-link [targa]="a" [enabled]="pin?.competencePlate != 'I'" [anomalyCode]="d" [ngClass]="isError || pin != null ? '' : 'hidden'"></app-contract-link>
<app-pin [targa]="a" [problemCode]="d" (pinEvent)="onPinEvent($event)" (errorEvent)="onErrorEvent()">
</app-pin>

<div *ngIf="!isError && pin?.codeMessage !== 'DEVICE_OK'" [ngClass]="isError || pin != null ? '' : 'hidden'">
    <div  class="text-center" *ngIf="!isError && pin?.competencePlate != 'I'">
      Per informazioni aggiornate sullo stato del dispositivo e sul contratto, usa lo "<app-contract-link [targa]="a" [enabled]="pin?.competencePlate != 'I'" mode="body" [anomalyCode]="d"></app-contract-link>".
    </div>
    <div  class="text-center margin-bottom" *ngIf="!isError">
      Per consultare le risposte alle domande più frequenti(FAQ), seleziona "Procedi".
    </div>

  <div class="text-center mu-fixed-btn-container" *ngIf="!isError">
    <button *ngIf="pin?.competencePlate === 'I'" class="btn mu-btn btn-primary mr-2"
      (click)="onBackHome()">Home</button>
    <button class="btn mu-btn btn-primary" (click)="onProcedi()">Procedi</button>
  </div>
</div>