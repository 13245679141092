// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  
  // accesso tramite vpn
  //base_url: 'http://10.188.11.33:3000/api',
  //resources_url: 'http://10.188.11.33:2805'

  // accesso senza vpn
  //base_url: 'https://web800test.metasystem.it/api',
  //resources_url: 'https://web800test.metasystem.it'
  base_url: '/api',
  anomalyCodes: ['004','009','007','016','017']
  //resources_url: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
