import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Pin } from "../models/pin.model";

export class SmsPageCaseComponent implements OnInit {
    a: string = null;
    b: string = null;
    c: string = null;
    d: string = null;
    pin: Pin = null;
    isError = false;
    name: string;
    faqPage:string;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        faqPage:string) {
        this.name = this.constructor.name;
        this.faqPage =faqPage
    }

    ngOnInit() {
        //console.log('  [SmsPageCase004Component] this.pin.codeMessage + p --> ', this.pin.codeMessage);
        this.route.queryParams.subscribe(p => {
            console.log(' + p --> ', p);

            this.a = p.a;
            this.b = p.b;
            this.c = p.c;
            this.d = p.d;
            console.log(`[${this.name}] + ngOnInit() --> this.a (targa)-->` , this.a);
            console.log(`[${this.name}] + ngOnInit() --> this.b (companyId)-->`, this.b);
            console.log(`[${this.name}] + ngOnInit() --> this.c (imeiCode)-->`, this.c);
            console.log(`[${this.name}] + ngOnInit() --> this.d (problemCode)-->`, this.d);
        });


    }



    onPinEvent(pin: Pin) {
        console.log( `[${this.name}] + onPinEvent() --> pin 1 --> `, pin);
        console.log( `[${this.name}] this.pin.codeMessage + pin 1 --> `, pin.codeMessage);
    
        this.pin = pin;
        console.log(`[${this.name}] this.pin.codeMessage + 2 --> `, pin.codeMessage);
        console.log(`[${this.name}] this.isError --> `, this.isError);
    
    
    
      }
    
      onErrorEvent() {
        this.isError = true;
      }
    
      onProcedi() {
        this.router.navigate(
          [this.faqPage],
          {
            queryParams: {
              p: this.pin.pin,
              b: this.b,
              created: new Date(this.pin.created).getTime(),
              expirationDate: new Date(this.pin.expirationDate).getTime()
            },
            queryParamsHandling: 'merge'
          }
        );
      }

}
