import * as tslib_1 from "tslib";
import { FaqLivello1 } from './../../models/faq-generico.model';
import { OnInit } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { hasBrowserBeenRefreshed } from '../../app.component';
import { environment } from 'src/environments/environment';
/*
  // URLS FOR TESTING
  http://localhost:4200/sms?a=EB588GG&b=U&c=123&d=004
  http://localhost:4200/sms?a=ET441MD&b=U&c=123&d=009
*/
export class PinPageComponent {
    constructor(route, pinsService, errorsService, router, faqService) {
        this.route = route;
        this.pinsService = pinsService;
        this.errorsService = errorsService;
        this.router = router;
        this.faqService = faqService;
        this.a = null;
        this.b = null;
        this.d = null;
        this.p = null;
        this.c = null;
        this.exit_message = null;
        this.level1Faq = null;
        this.level2Faq = null;
        this.note = null;
        this.isLoading = false;
        this.errorStatus = null;
        this.errorMessage = null;
        this.handleErrorMessage = null;
        this.pin = null;
        this.created = null;
        this.endValidity = null;
        this.isSms = false;
        this.faExclamationCircle = faExclamationCircle;
        //communicationType: CommunicationType = CommunicationType.N;
        this.communicationType = 'N';
        this.status = 'notset';
        // ID CODE ADDED
        this.exitCodeID = null;
        this.ts = null;
        this.anomalyCode = [];
        this.userRefreshedPage = hasBrowserBeenRefreshed;
        console.log('[PinPageComponent] REFRESHED ??  ', this.userRefreshedPage);
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event.navigationTrigger === 'popstate') {
                console.log('[PinPageComponent] popstate (BACK ?? ) ', event.navigationTrigger);
                if (this.isSms === true && (this.anomalyCode.includes(this.d))) {
                    console.log(' [PinPageComponent] + this.isSms --> (PREPARE REDIRECT/WARNING) ', this.isSms);
                    // SHOW PAGE SMS
                    this.router.navigate(['/sms-flow-end']);
                }
                else {
                    this.router.navigate(['/']);
                }
            }
        });
    }
    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.anomalyCode = environment.anomalyCodes;
            this.route.queryParams.subscribe((p) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                console.log(' + p --> ', p);
                this.a = p.a;
                this.b = p.b;
                this.d = p.d;
                this.p = p.p;
                this.c = p.c;
                this.ts = p.ts;
                if (this.anomalyCode.includes(this.d)) {
                    this.exit_message = p.exit;
                    this.exitCodeID = p.paramExitID;
                }
                this.level1Faq = p.level1Faq;
                this.level2Faq = p.level2Faq === undefined ? '' : p.level2Faq;
                this.note = p.note === undefined || p.note === '' ? '' : p.note;
                this.created = Number(p.created);
                this.communicationType = p.communicationType == 'si' ? 'E' : 'N';
                if (this.note !== '' && this.communicationType === 'N') {
                    this.communicationType = 'V';
                    console.log("this.note: ", this.note);
                    console.log("this.communicationType: ", this.communicationType);
                }
                this.endValidity = new Date(Number(p.expirationDate));
                this.pin = yield this.pinsService.pinData(this.a, this.p, this.anomalyCode.includes(this.d) ? 'S' : 'G');
                console.log('[PinPageComponent] -> Pin Object', this.pin);
                if (!(this.pin.pin == null)) {
                    this.isLoading = false;
                    // this.pin.pin = Number(this.p);
                    // this.pin.expirationDate = this.endValidity
                    // this.resultDays = this.pin.daysValidityPin();
                    this.pin.communicationType = this.communicationType;
                    console.log(' [PinPageComponent] + retrieved pin: this.pin --> ', this.pin);
                    console.log('[PinPageComponent] + this.resultDays --> ', this.resultDays);
                }
                console.log('[PinPageComponent] + ngOnInit() --> this.a (targa) --> ', this.a);
                console.log('[PinPageComponent] + ngOnInit() --> this.d (Page Code) --> ', this.d);
                console.log(' [PinPageComponent] + ngOnInit() --> this.c (imeiCode) --> ', this.c);
                console.log(' [PinPageComponent] + ngOnInit() --> this.level1Faq --> ', this.level1Faq);
                console.log('[PinPageComponent]  + ngOnInit() --> this.level2Faq --> ', this.level2Faq);
                console.log(' [PinPageComponent] + ngOnInit() --> this.note --> ', this.note);
                console.log(' [PinPageComponent] + ngOnInit() --> this.resultDays --> ', this.resultDays);
                console.log(' [PinPageComponent] + ngOnInit() --> this.created --> ', this.created);
                console.log(' [PinPageComponent] + ngOnInit() --> this.communicationType --> ', this.communicationType);
                console.log(' [PinPageComponent] + ngOnInit() --> this.endValidity --> ', this.endValidity);
                console.log(' [PinPageComponent] + ngOnInit() --> Object Pin --> ', this.pin);
                switch (this.d) {
                    case '004':
                    case '007':
                    case '009':
                    case '016':
                    case '017':
                        this.isSms = true;
                        break;
                }
                console.log(' [PinPageComponent] + ngOnInit() --> this.isSms --> ', this.isSms);
                let x = this.communicationType;
                console.log(' [PinPageComponent] + ngOnInit() --> this.isSms --> ', this.isSms);
                let faqData = 'Page Code: ' + this.d + ' - ' +
                    'faq: ' + (this.level1Faq == undefined ? 'NON COMPILATO' : this.level1Faq) + ' - ' +
                    'note: ' + (this.note == '' ? 'NON COMPILATO' : this.note);
                // 'communicationType: ' + x;
                if (this.anomalyCode.includes(this.d)) {
                    console.log(' [PinPageComponent] + this.d--> ', this.d);
                    let faqs = yield this.faqService.getFaqFlussoSMS(this.d, this.c).toPromise();
                    if (this.d === '009') {
                        let result = faqs.filter(a => a.nome == this.level1Faq);
                        if (result.length > 0) {
                            console.log(' [PinPageComponent] + ngOnInit() --> faqs --> ', result);
                            faqData =
                                'Page Code: ' + this.d + ' - ' +
                                    'faq: ' + (this.level1Faq == undefined ? 'NON COMPILATO' : (this.level1Faq + ' - ' + result[0].testo + ' - ')) +
                                    'note: ' + (this.note == '' ? 'NON COMPILATO' : this.note);
                            console.log(' [PinPageComponent] + ngOnInit() --> faqData --> ', faqData);
                        }
                    }
                }
                else {
                    let faqlivello1 = yield this.faqService.getFaqFlussoGenerico(this.p, this.a).toPromise();
                    console.log(' + ngOnInit() --> faqlivello1 --> ', faqlivello1);
                    if (faqlivello1.length > 0) {
                        let result = faqlivello1.filter(a => a.id == parseInt(this.level1Faq));
                        if (result.length > 0) {
                            let faqlivello1Descr = result[0].name;
                            console.log(' + ngOnInit() --> faqlivello1 name --> ', faqlivello1Descr);
                            let faqlivello2 = result[0].faqLivello2.filter(x => x.codiceFaq == this.level2Faq);
                            if (faqlivello2.length > 0) {
                                let faqlivello2Descr = faqlivello2[0].codiceFaq;
                                if ((faqlivello2[0].faqs !== undefined) && faqlivello2[0].faqs.length > 0) {
                                    faqlivello2Descr = faqlivello2Descr + ' -  ' + faqlivello2[0].faqs[0].testoFaq;
                                }
                                faqData =
                                    'Page Code: ' + this.d + ' - ' +
                                        'level1Faq: ' + faqlivello1Descr + ' - ' +
                                        'level2Faq: ' + (faqlivello2Descr == undefined ? 'NON COMPILATO' : faqlivello2Descr) + ' - ' +
                                        'note: ' + (this.note == '' ? 'NON COMPILATO' : this.note);
                            }
                            else {
                                // Caso con selezione solo del primo livello di FAQ
                                faqData =
                                    'Page Code: ' + this.d + ' - ' +
                                        'level1Faq: ' + faqlivello1Descr + ' - ' +
                                        'level2Faq: ' + 'NON COMPILATO' + ' - ' +
                                        'note: ' + (this.note == '' ? 'NON COMPILATO' : this.note);
                            }
                        }
                        else {
                            // messaggio usato nel flusso generico 000 per la faq "Altro/Nessuna di queste" e abbiamo altre faq
                            faqData =
                                'Page Code: ' + this.d + ' - ' +
                                    'level1Faq: ' + 'Altro/Nessuna di queste' + ' - ' +
                                    'note: ' + (this.note == '' ? 'NON COMPILATO' : this.note);
                        }
                    }
                    else {
                        // messaggio usato nel flusso generico 000 per la faq "Altro/Nessuna di queste" e non abbiamo altre faq
                        faqData =
                            'Page Code: ' + this.d + ' - ' +
                                'level1Faq: ' + 'Altro/Nessuna di queste' + ' - ' +
                                'note: ' + (this.note == '' ? 'NON COMPILATO' : this.note);
                    }
                }
                console.log(' [PinPageComponent] + ngOnInit() --> faqData --> ', faqData);
                this.isLoading = true;
                if (this.userRefreshedPage === false) {
                    console.log('[PinPageComponent] REFRESHED PAGE ??? ', this.userRefreshedPage);
                    if (this.anomalyCode.includes(this.d)) {
                        console.log(' SMS??? [PinPageComponent] + before patchPinFlussoSms  -->  [0]this.pin.pin ', this.pin.pin);
                        console.log(' SMS??? [PinPageComponent] + before patchPinFlussoSms  -->  otherInfo:faqData  ', faqData);
                        console.log(' SMS???  [PinPageComponent] + before patchPinFlussoSms  -->  communicationType:x --> ', x);
                        this.pinsService.patchPinFlussoSms(this.pin.pin, { flussoSmsCodiceExit: this.exitCodeID, otherInfo: faqData, communicationType: x }).subscribe(response => {
                            this.status = 'OK';
                            this.isLoading = false;
                            console.log(' [PinPageComponent] + patchPinFlussoSms --> response --> ', response);
                            this.resultDays = this.pin.daysValidityPin();
                            console.log("ResultDays: ", this.resultDays);
                        }, error => {
                            this.status = 'KO';
                            this.isLoading = false;
                            console.log('  [PinPageComponent] + patchPinFlussoSms --> error --> ', error);
                            this.router.navigate(['error']);
                            // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
                            // this.errorStatus = errObj.statusHeader;
                            // this.errorMessage = errObj.statusMessage;
                        });
                    }
                    else {
                        this.pinsService.patchPinFlussoGenerico(this.pin.pin, { otherInfo: faqData, communicationType: x }).subscribe(response => {
                            this.isLoading = false;
                            this.status = 'OK';
                            this.resultDays = this.pin.daysValidityPin();
                            console.log("ResultDays: ", this.resultDays);
                            console.log(' [PinPageComponent] + patchPinFlussoGenerico --> response --> ', response);
                        }, error => {
                            this.isLoading = false;
                            this.status = 'KO';
                            console.log(' [PinPageComponent] + patchPinFlussoGenerico --> error --> ', error);
                            this.router.navigate(['error']);
                            // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
                            // this.errorStatus = errObj.statusHeader;
                            // this.errorMessage = errObj.statusMessage;
                        });
                    }
                }
                else {
                    this.isLoading = false;
                    /*if(this.isSms  === true && ( this.d === '004' || this.d === '009')){
                       console.log(' [PinPageComponent] + this.isSms --> (PREPARE REDIRECT/WARNING) ', this.isSms);
                       this.router.navigate(['/sms-flow-end']);
                     }*/
                }
            }));
        });
    }
    // private getPinValidity(created: number): Date {
    //   return new Date((new Date(created).getTime() + (5 + 84600 * 1000)));
    // }
    onCloseAlert() {
        this.handleErrorMessage = this.errorMessage;
        console.log(' + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
        console.log(' + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);
        this.errorStatus = null;
        this.errorMessage = null;
    }
    onBackHome() {
        //this.router.navigate(['/home']);
        this.router.navigate(['/home'], { queryParams: { ts: new Date().valueOf() } });
    }
}
