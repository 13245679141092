/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sms-page-case000.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/contract-link/contract-link.component.ngfactory";
import * as i3 from "../../shared/contract-link/contract-link.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../shared/pin/pin.component.ngfactory";
import * as i7 from "../../shared/pin/pin.component";
import * as i8 from "../../services/pins.service";
import * as i9 from "../../services/errors.service";
import * as i10 from "./sms-page-case000.component";
var styles_SmsPageCase000Component = [i0.styles];
var RenderType_SmsPageCase000Component = i1.ɵcrt({ encapsulation: 0, styles: styles_SmsPageCase000Component, data: {} });
export { RenderType_SmsPageCase000Component as RenderType_SmsPageCase000Component };
function View_SmsPageCase000Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Per informazioni aggiornate sullo stato del dispositivo e sul contratto, usa lo \""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-contract-link", [["mode", "body"]], null, null, null, i2.View_ContractLinkComponent_0, i2.RenderType_ContractLinkComponent)), i1.ɵdid(3, 114688, null, 0, i3.ContractLinkComponent, [i4.Router], { targa: [0, "targa"], anomalyCode: [1, "anomalyCode"], enabled: [2, "enabled"], mode: [3, "mode"] }, null), (_l()(), i1.ɵted(-1, null, ["\". "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.a; var currVal_1 = _co.d; var currVal_2 = (((_co.pin == null) ? null : _co.pin.competencePlate) != "I"); var currVal_3 = "body"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SmsPageCase000Component_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "text-center margin-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Per consultare le risposte alle domande pi\u00F9 frequenti(FAQ), seleziona \"Procedi\". "]))], null, null); }
function View_SmsPageCase000Component_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn mu-btn btn-primary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBackHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Home"]))], null, null); }
function View_SmsPageCase000Component_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-center mu-fixed-btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase000Component_5)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn mu-btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onProcedi() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Procedi"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.pin == null) ? null : _co.pin.competencePlate) === "I"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_SmsPageCase000Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase000Component_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase000Component_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase000Component_4)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isError || (_co.pin != null)) ? "" : "hidden"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.isError && (((_co.pin == null) ? null : _co.pin.competencePlate) != "I")); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.isError; _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.isError; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_SmsPageCase000Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-contract-link", [], null, null, null, i2.View_ContractLinkComponent_0, i2.RenderType_ContractLinkComponent)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 114688, null, 0, i3.ContractLinkComponent, [i4.Router], { targa: [0, "targa"], anomalyCode: [1, "anomalyCode"], enabled: [2, "enabled"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-pin", [], null, [[null, "pinEvent"], [null, "errorEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pinEvent" === en)) {
        var pd_0 = (_co.onPinEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("errorEvent" === en)) {
        var pd_1 = (_co.onErrorEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_PinComponent_0, i6.RenderType_PinComponent)), i1.ɵdid(5, 245760, null, 0, i7.PinComponent, [i8.PinsService, i4.Router, i9.ErrorsService], { targa: [0, "targa"], problemCode: [1, "problemCode"] }, { pinEvent: "pinEvent", errorEvent: "errorEvent" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase000Component_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isError || (_co.pin != null)) ? "" : "hidden"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.a; var currVal_2 = _co.d; var currVal_3 = (((_co.pin == null) ? null : _co.pin.competencePlate) != "I"); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.a; var currVal_5 = _co.d; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_6 = (!_co.isError && (((_co.pin == null) ? null : _co.pin.codeMessage) !== "DEVICE_OK")); _ck(_v, 7, 0, currVal_6); }, null); }
export function View_SmsPageCase000Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sms-page-case000", [], null, null, null, View_SmsPageCase000Component_0, RenderType_SmsPageCase000Component)), i1.ɵdid(1, 114688, null, 0, i10.SmsPageCase000Component, [i4.ActivatedRoute, i4.Router, i5.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmsPageCase000ComponentNgFactory = i1.ɵccf("app-sms-page-case000", i10.SmsPageCase000Component, View_SmsPageCase000Component_Host_0, {}, {}, []);
export { SmsPageCase000ComponentNgFactory as SmsPageCase000ComponentNgFactory };
