import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Pin } from '../models/pin.model';
import { FaqLivello1 } from '../models/faq-generico.model';
import { faqSms } from '../models/faq-sms.model';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  base_url: string = environment.base_url;

  constructor(private http: HttpClient) { }

  getFaqFlussoGenerico(pin: number, vehiclePlate: string) {
    return this.http.get<FaqLivello1[]>(`${this.base_url}/genericflow/faq?pin=${pin}&vehiclePlate=${vehiclePlate}`);
  }

  getFaqFlussoSMS(anomalyCode: string = '004' || '009', imeiCode: string) {
    return this.http.get<any>(`${this.base_url}/smsflow/faq?anomalyCode=${anomalyCode}&imeiCode=${imeiCode}`);
  }

  getFaqFlussoGenerico_old(pin: Pin) {
    return this.http.get<FaqLivello1[]>(`${this.base_url}/genericflow/faq?pin=${pin.pin}&vehiclePlate=${pin.vehiclePlate}`);
  }


  // async getLevel0Faq(smsCode: string) {
  //   // return this.faq[smsCode];

  //   return this.http.get(
  //     this.base_url +
  //     this.faqPath
  //   ).pipe(map( faq => {

  //     console.log(' + getLevel0Faq --> faq[' + smsCode + '] --> ', faq[smsCode]);

  //     return faq[smsCode];
  //   }));
  // }

  // getLevel1Faq(smsCode: string, level1Code: string) {
  //   // const faqTree = this.faq[smsCode];
  //   // return faqTree[level1Code];

  //   return this.http.get(
  //     this.base_url +
  //     this.faqPath
  //   ).pipe(map( faq => {

  //     console.log(' + getLevel1Faq --> faq --> ', faq);

  //     const faqTree = faq[smsCode];

  //     return faqTree[level1Code];
  //   }));
  // }
}
