<app-loading-spinner *ngIf="isLoading;"></app-loading-spinner>

  <div *ngIf="!isLoading ">
    <div class="text-center text-margin-bottom">

      <h3>Stato Dispositivo e Contratto</h3>
      <!--p></p>
      <app-targa [targa]="targa"></app-targa-->
      <p>In questa pagina puoi verificare lo stato del  dispositivo e del contratto assicurativo per la targa {{targa}}; questi dati vengono aggiornati in funzione degli ultimi eventi registrati nei sistemi della compagnia assicurativa.</p>
      
        
        
      <div class="section">
        <h4>Dati Dispositivo</h4>
        
        <div class="row">
            <div class="title">Tipo dispositivo:</div>
            <div class="value">{{getDeviceType()}}</div>
        </div>
        
        <div class="row" *ngIf="getIMEI() != null">
            <div class="title">IMEI Dispositivo:</div>
            <div class="value">{{getIMEI()}}</div>
        </div>
        
        <div class="row">
            <div class="title">Stato Dispositivo:</div>
            <div class="value">{{getDeviceStatus() || "Non disponibile per l’attuale stato del Contratto"}}</div>
        </div>
      </div>




        
      <div class="section" *ngIf="shouldDisplayAnomalySection()">
        
        <h4>Anomalia</h4>
        
        <div class="row">
            <div class="title">Stato Anomalia:</div>
            <div class="value">{{getAnomalyState()}}</div>
        </div>
        
        <div class="row">
            <div class="title">Tipo Anomalia:</div>
            <div class="value">{{getAnomalyType()}}</div>
        </div>
      </div>


        
      <div class="section">
            <h4>Dati Contratto</h4>
            
            <div class="row">
                <div class="title">Stato Contratto:</div>
                <div class="value">{{getContractStaus()}}</div>
            </div>
            
            <div class="row" *ngIf="displayFull && this.contractInfo.telematicContractSummary.status != 1">
                <div class="title">Data Inizio Contratto:</div>
                <div class="value">{{getContractStartDate()}}</div>
            </div>
            
            <div class="row" *ngIf="displayFull && this.contractInfo.telematicContractSummary.status != 1">
                <div class="title">Data Fine Contratto:</div>
                <div class="value">{{getContractEndDate()}}</div>
            </div>
            
            <div class="row" *ngIf="!displayFull && this.contractInfo.telematicContractSummary.status != 1">
                <div class="title">Validità Contratto:</div>
                <div class="value">{{getContractValidity()}}</div>
            </div>

            <div class="row">
                <div class="title">Provenienza Polizza:</div>
                <div class="value">{{getSalesChannel()}}</div>
            </div>
            
            <div class="row" *ngIf="this.contractInfo.telematicContractSummary.salesChannel == 0 || this.contractInfo.telematicContractSummary.salesChannel == 4">
                <div class="title">Agenzia:</div>
                <div class="value">{{getSalesAgency()}}</div>
            </div>
            
            <div class="row" *ngIf="this.contractInfo.installerInfo">
                <div class="title">Installatore:</div>
                <div class="value">{{getInstaller()}}
                </div>
            </div>
      </div>




    </div>
  </div>

  <div class="text-center mu-fixed-btn-container">
    <button class="btn mu-btn btn-primary" (click)="onBack()">Indietro</button>
  </div>
