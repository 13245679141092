import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FaqService {
    constructor(http) {
        this.http = http;
        this.base_url = environment.base_url;
    }
    getFaqFlussoGenerico(pin, vehiclePlate) {
        return this.http.get(`${this.base_url}/genericflow/faq?pin=${pin}&vehiclePlate=${vehiclePlate}`);
    }
    getFaqFlussoSMS(anomalyCode = '004' || '009', imeiCode) {
        return this.http.get(`${this.base_url}/smsflow/faq?anomalyCode=${anomalyCode}&imeiCode=${imeiCode}`);
    }
    getFaqFlussoGenerico_old(pin) {
        return this.http.get(`${this.base_url}/genericflow/faq?pin=${pin.pin}&vehiclePlate=${pin.vehiclePlate}`);
    }
}
FaqService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FaqService_Factory() { return new FaqService(i0.ɵɵinject(i1.HttpClient)); }, token: FaqService, providedIn: "root" });
