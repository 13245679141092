import { exit_message } from './../../models/faq-sms.model';
import { PinsService } from 'src/app/services/pins.service';

import { Component, OnInit,OnDestroy} from '@angular/core';
import { Pin } from 'src/app/models/pin.model';
import { ActivatedRoute, Router,NavigationStart } from '@angular/router';
import { FaqService } from 'src/app/services/faq.service';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ErrorMeta } from 'src/app/models/errorMeta.model';
import { ErrorsService } from 'src/app/services/errors.service';
import { faqSms, faqSms009 } from 'src/app/models/faq-sms.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-faq-case009',
  templateUrl: './faq-case009.component.html',
  styleUrls: ['./faq-case009.component.scss']
})

export class FaqCase009Component implements OnInit {

  a: string = null;
  note: string = null;
  d: string = null;
  p: number = null;
  b: string = null;
  c: string = null;

  errorStatus: string = null;
  errorMessage: string = null;
  isLoading = false;
  pin: Pin = null;
  faqTree: faqSms009 = null;
  level1Faq: string = null;
  faCheckSquare = faCheckSquare;
  faSquare = faSquare;
  faChevronRight = faChevronRight;

  exitID: string = null;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private faqService: FaqService,
    private errorsService: ErrorsService, private pinsService: PinsService) {


   }


  ngOnInit() {



    this.faqTree = new faqSms009();

    this.route.queryParams.subscribe(async p => {
      console.log(' + p --> ', p);
      this.a = p.a;
      this.b = p.b; //company 
      this.d = p.d;
      this.p = p.p;
      this.c = p.c;

      console.log(' [FaqCase009Component] + ngOnInit() --> this.a (targa) --> ', this.a);
      console.log(' [FaqCase009Component] + ngOnInit() --> this.b (company) --> ', this.b);
      console.log(' [FaqCase009Component] + ngOnInit() --> this.d (problemCode) --> ', this.d);
      console.log(' [FaqCase009Component] + ngOnInit() --> this.c (imeiCode) --> ', this.c);

      if (!this.a || !this.d) {
        this.errorStatus = 'Non è possibile continuare la navigazione';
        this.errorMessage = 'La targa e il problem code sono obbligatori';
      }

      try {
        let faq = await (await this.faqService.getFaqFlussoSMS(this.d, this.c)).toPromise();
        this.faqTree.faq = faq;
        console.log(' + ngOnInit() --> this.faqTree -->', this.faqTree);

        this.faqTree.faq.forEach(faqElement => {
          faqElement.isVisible = false;
          faqElement.note = "";
        });

        this.pin = await this.pinsService.pinData(this.a, this.p, 'S');
      }
      catch (err) {
        console.log(err);

        return this.router.navigate(['error']);
      }

    }, error => {
      console.log('Error message ---> ', error);

      this.router.navigate(['error']);

      // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
      // this.errorStatus = errObj.statusHeader;
      // this.errorMessage = errObj.statusMessage;
    });





  }

  onClickLevel1Faq(faq: any) {
    this.exitID = faq.exit;

    console.log('[FaqCase009Component] + onClickLevel1Faq() --> faq -->', faq);
    console.log(' [FaqCase009Component]+ onClickLevel1Faq() --> faq -->', faq.exit, ' this.exitID ' , this.exitID );

    this.level1Faq = !faq.isVisible ? faq.nome : null;
    this.note = faq.note;
    console.log(' [FaqCase009Component] + onClickLevel1Faq() --> this.level1Faq -->', this.level1Faq);
    console.log(' [FaqCase009Component] + onClickLevel1Faq() --> this.note -->', this.note);


    this.faqTree.faq.forEach(faqElement => {
      if (faqElement.nome === faq.nome) {
        faqElement.isVisible = !faqElement.isVisible;
      } else {
        faqElement.isVisible = false;
      }

      if (!faqElement.isVisible) {
        faqElement.note = "";
      }
    });
  }

  onClickAltro() {
    console.log(' + onClickAltro()');

    this.router.navigate(
      ['level2-faq'],
      {
        queryParamsHandling: 'preserve'
      });
  }

  onRegistra() {
    console.log(' [FaqCase009Component] + onRegistra() --> this.note-->', this.faqTree);
    console.log(' [FaqCase009Component] + onRegistra() --> this.level1Faq --> ', this.level1Faq);
    console.log(' [FaqCase009Component] + onRegistra() --> this.note-->', this.note);

    console.log(' [FaqCase009Component]+ onClickLevel1Faq() --> this.exitID ' , this.exitID );

    this.faqTree.faq.forEach(faqElement => {
      if (faqElement.isVisible) {
        this.note = faqElement.note;
      }
    });
    this.clearSessionStorage();

    this.router.navigate(
      ['pin-page'],
      {
        queryParams: {
          a: this.a,
          b: this.b,
          level1Faq: this.level1Faq,
          note: this.note,
          paramExitID : this.exitID,
          communicationType: this.faqTree.email ? 'si' : 'no',
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  onCloseAlert() {

    console.log(' + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
    console.log(' + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);

    this.errorStatus = null;
    this.errorMessage = null;
  }

  clearSessionStorage(){
    if (sessionStorage.getItem('PinStorage')) {
      console.log('::: (clearSessionStorage) CLEARING SESSION STORAGE :::');
      sessionStorage.removeItem('PinStorage');
    }
  }
}
