import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-targa',
  templateUrl: './targa.component.html',
  styleUrls: ['./targa.component.scss']
})
export class TargaComponent {

  @Input() targa: string;
}
