/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sms-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "../../shared/loading-spinner/loading-spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./sms-page.component";
import * as i6 from "@angular/router";
import * as i7 from "../../services/pins.service";
import * as i8 from "../../services/errors.service";
var styles_SmsPageComponent = [i0.styles];
var RenderType_SmsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmsPageComponent, data: {} });
export { RenderType_SmsPageComponent as RenderType_SmsPageComponent };
function View_SmsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingSpinnerComponent, [], null, null)], null, null); }
export function View_SmsPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SmsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sms-page", [], null, null, null, View_SmsPageComponent_0, RenderType_SmsPageComponent)), i1.ɵdid(1, 114688, null, 0, i5.SmsPageComponent, [i6.ActivatedRoute, i6.Router, i7.PinsService, i8.ErrorsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmsPageComponentNgFactory = i1.ɵccf("app-sms-page", i5.SmsPageComponent, View_SmsPageComponent_Host_0, {}, {}, []);
export { SmsPageComponentNgFactory as SmsPageComponentNgFactory };
