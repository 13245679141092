<!-- <app-alert [status]="errorStatus" [message]="errorMessage" *ngIf="errorStatus" (closeAlert)="onCloseAlert()">
</app-alert> -->

<app-loading-spinner *ngIf="isLoading; else contentBlock"></app-loading-spinner>

<ng-template #contentBlock>
  <app-contract-link [targa]="a" [companyId]="b"  [anomalyCode]="'009'"></app-contract-link>
  <h3 class="text-center mb-2">Seleziona la voce corrispondente alla tua condizione, aggiungi se vuoi altre informazioni
    nel campo "note" in fondo alla pagina, poi clicca su "<b>Registra e Procedi</b>" per inviarci la risposta:</h3>
  <!-- <app-targa [targa]="a"></app-targa> -->

  <ul class="mu-list-group list-group list-group-flush margin-bottom">
    <li class="list-group-item mu-list-group-item mu-list-group-item-2nd-level" *ngFor="let faq of faqTree.faq"
      [ngClass]="faq.isVisible ? 'active' : ''">
      <span class="mu-list-group-item-text" (click)="onClickLevel1Faq(faq)">
        <!-- {{ faq.nome }} -->
        <div [innerHtml]="faq.nome"></div>
        <!-- <i class="fa fa-check-square" *ngIf="faq.isVisible"></i>
        <i class="fa fa-square" *ngIf="!faq.isVisible"></i> -->
        <fa-icon [icon]="faCheckSquare" *ngIf="faq.isVisible"></fa-icon>
        <fa-icon [icon]="faSquare" *ngIf="!faq.isVisible"></fa-icon>
      </span>

      <div class="mu-list-group-item-description" *ngIf="faq.isVisible">
        <!-- <p>{{ faq.testo }}</p> -->
        <div class="mb-3" [innerHtml]="faq.testo"></div>
        <textarea class="mu-form-control mu-textarea form-control" rows="4" id="note" name="note" placeholder="Note..."
          [(ngModel)]="faq.note">
      </textarea>
      </div>
    </li>

    <!-- <li class="list-group-item mu-list-group-item" (click)="onClickAltro()">
      <span class="mu-list-group-item-text">Altro / Nessuna di queste</span>
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </li> -->
  </ul>

  <div class="form-check margin-bottom" *ngIf="pin?.hasEmail">
    <input class="form-check-input" type="checkbox" id="email" name="email" [(ngModel)]="faqTree.email">
    <label class="form-check-label" for="email">
      Desideri continuare questo dialogo via email?
    </label>
  </div>

  <div class="text-center mu-fixed-btn-container">
    <button class="mu-btn btn btn-primary" [disabled]="!level1Faq" (click)="onRegistra()">Registra e Procedi</button>
  </div>
</ng-template>