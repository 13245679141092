import { Component, OnInit,OnDestroy } from '@angular/core';
import { ActivatedRoute, Router,NavigationStart } from '@angular/router';
import { FaqService } from 'src/app/services/faq.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ErrorMeta } from 'src/app/models/errorMeta.model';
import { ErrorsService } from 'src/app/services/errors.service';
import { Pin } from 'src/app/models/pin.model';
import { PinsService } from 'src/app/services/pins.service';
import { FaqLivello1 } from 'src/app/models/faq-generico.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-level1-faq',
  templateUrl: './level1-faq.component.html',
  styleUrls: ['./level1-faq.component.scss']
})
export class Level1FaqComponent implements OnInit {

  a: string = null;
  d: string = null;
  p: number = null;

  errorStatus: string = null;
  errorMessage: string = null;
  isLoading = false;
  faqTree: any = null;
  level1Faq: FaqLivello1[] = null;
  faArrowRight = faArrowRight;
  pin: Pin = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private faqService: FaqService,
    private errorsService: ErrorsService,
    private location: Location,
    private pinsService: PinsService) {


   }


  ngOnInit() {
    this.route.queryParams.subscribe(async p => {
      console.log(' [Level1FaqComponent] + p --> ', p);

      this.a = p.a;
      this.d = p.d;
      this.p = p.p;

      console.log(' [Level1FaqComponent] + ngOnInit() --> this.a (targa) --> ', this.a);
      console.log(' [Level1FaqComponent] + ngOnInit() --> this.d (problemCode) --> ', this.d);



      try {
        this.faqTree = await (await this.faqService.getFaqFlussoGenerico(this.p, this.a)).toPromise();
      } catch (err) {
        console.log(err);

        return this.router.navigate(['error']);
      }



      console.log(' [Level1FaqComponent] + ngOnInit() --> this.faqTree -->', this.faqTree);

      // this.level1Faq = Object.keys(this.faqTree);

      // for (let i = 0; i < this.level1Faq.length; i++) {
      //   console.log(' + this.faqTree[' + this.level1Faq[i] + '] -->', this.faqTree[this.level1Faq[i]]);
      // }

      console.log(' [Level1FaqComponent] + ngOnInit() --> this.level1Faq -->', this.level1Faq);

    }, error => {
      console.log('Error message --->', error);

      this.router.navigate(['error']);

      // const errObj: ErrorMeta = this.errorsService.getErrorParse(error.status);
      // this.errorStatus = errObj.statusHeader;
      // this.errorMessage = errObj.statusMessage;
    });




  }
  async onPinEvent(pin: Pin) {
    console.log(' + onPinEvent() --> pin --> ', pin);

    this.pin = pin;

    try {
      this.faqTree = await (await this.faqService.getFaqFlussoGenerico(this.p, this.a)).toPromise();
    } catch (err) {
      console.log(err);

      return this.router.navigate(['error']);
    }

    console.log(' [Level1FaqComponent] + ngOnInit() --> this.faqTree -->', this.faqTree);

    // this.level1Faq = Object.keys(this.faqTree);

    // for (let i = 0; i < this.level1Faq.length; i++) {
    //   console.log(' + this.faqTree[' + this.level1Faq[i] + '] -->', this.faqTree[this.level1Faq[i]]);
    // }

    console.log(' [Level1FaqComponent] + ngOnInit() --> this.level1Faq -->', this.level1Faq);
  }

  onErrorEvent() {
    //this.isError = true;
  }

  onClickLevel1Faq(level1Faq: string) {
    console.log(' [Level1FaqComponent] + onClickLevel1Faq() --> level1Faq --> ', level1Faq);

    this.router.navigate(
      ['level2-faq'],
      {
        queryParams: { level1Faq },
        queryParamsHandling: 'merge'
      });
  }

  onClickAltro() {
    console.log(' + onClickAltro()');

    this.router.navigate(
      ['level2-faq'],
      {
        queryParamsHandling: 'preserve'
      });
  }

  onCloseAlert() {

    console.log(' [Level1FaqComponent] + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
    console.log(' [Level1FaqComponent] + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);

    this.errorStatus = null;
    this.errorMessage = null;
  }

}
