export class Pin {
  constructor(
    public pin: number,
    public vehiclePlate: string,
    public created: Date,
    public expirationDate: Date,
    public competencePlate: string,
    public email: string,
    public deviceType: string,
    public communicationType: string,
    public otherInfo: string,
    public contractId: number,
    public insurancePolicy: string,
    public deviceStatus: string,
    public daysValidity: number,
    public codeMessage: string,
    public hasEmail: boolean,
    public insuranceStartDate: Date
  ) {
    this.daysValidity = this.daysValidityPin();
  }

  public daysValidityPin(): number {
    //return expirationDate.getDate() - currentDate.getDate();
    //return  this.datediff(this.parseDate(this.expirationDate), (this.parseDate(new Date().toDateString())))
    let created = new Date(this.created);
    let expirationDate = new Date(this.expirationDate);
    let today = new Date()
    //today.setDate(today.getDate() + 10);
    //console.log(today)
    if (today < expirationDate)
      return this.datediff(today, expirationDate)
    else
      return 0
  }
  datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    let diff = Math.abs(second.getTime() - first.getTime());
    let diffDays = Math.round(diff / (1000 * 3600 * 24));
    return diffDays;
  }
}