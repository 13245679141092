import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[id=targa]'
})
export class TrimInputDirective {

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  oninput(updatedValue: string) {
    let value: any = this.control.control.value;
    if (value != null && typeof value === 'string') {
      value = updatedValue.replace(/\s/g, '');
      this.control.control.setValue(value);
    }
  }

}
