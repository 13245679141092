/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./level1-faq.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "../../shared/loading-spinner/loading-spinner.component";
import * as i4 from "../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../shared/contract-link/contract-link.component.ngfactory";
import * as i8 from "../../shared/contract-link/contract-link.component";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "./level1-faq.component";
import * as i12 from "../../services/faq.service";
import * as i13 from "../../services/errors.service";
import * as i14 from "../../services/pins.service";
var styles_Level1FaqComponent = [i0.styles];
var RenderType_Level1FaqComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Level1FaqComponent, data: {} });
export { RenderType_Level1FaqComponent as RenderType_Level1FaqComponent };
function View_Level1FaqComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingSpinnerComponent, [], null, null)], null, null); }
function View_Level1FaqComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "mu-list-group-item list-group-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickLevel1Faq(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "mu-list-group-item-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(4, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.faArrowRight; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).title; var currVal_2 = i1.ɵnov(_v, 4).renderedIconHTML; _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_Level1FaqComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contract-link", [], null, null, null, i7.View_ContractLinkComponent_0, i7.RenderType_ContractLinkComponent)), i1.ɵdid(1, 114688, null, 0, i8.ContractLinkComponent, [i9.Router], { targa: [0, "targa"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "text-center mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Consulta le FAQ selezionando l'argomento di tuo interesse:"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "ul", [["class", "mu-list-group list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level1FaqComponent_3)), i1.ɵdid(6, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "li", [["class", "mu-list-group-item list-group-item mb-5"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickAltro() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "mu-list-group-item-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Altro/Nessuna di queste"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(11, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.a; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.faqTree; _ck(_v, 6, 0, currVal_1); var currVal_4 = _co.faArrowRight; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 11).title; var currVal_3 = i1.ɵnov(_v, 11).renderedIconHTML; _ck(_v, 10, 0, currVal_2, currVal_3); }); }
export function View_Level1FaqComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_Level1FaqComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["contentBlock", 2]], null, 0, null, View_Level1FaqComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_Level1FaqComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-level1-faq", [], null, null, null, View_Level1FaqComponent_0, RenderType_Level1FaqComponent)), i1.ɵdid(1, 114688, null, 0, i11.Level1FaqComponent, [i9.ActivatedRoute, i9.Router, i12.FaqService, i13.ErrorsService, i10.Location, i14.PinsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Level1FaqComponentNgFactory = i1.ɵccf("app-level1-faq", i11.Level1FaqComponent, View_Level1FaqComponent_Host_0, {}, {}, []);
export { Level1FaqComponentNgFactory as Level1FaqComponentNgFactory };
