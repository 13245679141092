import { Component, OnInit, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router , NavigationStart } from '@angular/router';
import { Pin } from 'src/app/models/pin.model';
import { Location } from '@angular/common';


@Component({
  selector: 'app-sms-page-case000',
  templateUrl: './sms-page-case000.component.html',
  styleUrls: ['./sms-page-case000.component.scss']
})
export class SmsPageCase000Component implements OnInit {

  a: string = null;
  d: string = null;
  pin: Pin = null;
  isError = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) { }


  ngOnInit() {


    this.route.queryParams.subscribe(p => {
      console.log(' + p --> ', p);

      this.a = p.a;
      this.d = p.d;

      console.log(' + ngOnInit() --> this.a (targa) --> ', this.a);
      console.log(' + ngOnInit() --> this.d (problemCode)--> ', this.d);

    });


  }

  onPinEvent(pin: Pin) {
    console.log(' + onPinEvent() --> pin --> ', pin);

    this.pin = pin;
  }

  onErrorEvent() {
    this.isError = true;
  }

  onProcedi() {

    var encodedString = btoa('aaa');

    this.router.navigate(
      ['level1-faq'],
      {
        queryParams: {
          p: this.pin.pin,
          a: this.pin.vehiclePlate,
          // created: new Date(this.pin.created).getTime(),
          // expirationDate: new Date(this.pin.expirationDate).getTime()
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  onBackHome() {
    //this.router.navigate(['home']);
    this.router.navigate(['/home'], { queryParams: { ts: new Date().valueOf() } });


  }
}
