/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./targa.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./targa.component";
var styles_TargaComponent = [i0.styles];
var RenderType_TargaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TargaComponent, data: {} });
export { RenderType_TargaComponent as RenderType_TargaComponent };
export function View_TargaComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "mu-targa-container mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "mu-targa-appended before"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["class", "text-center mb-0 font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "mu-targa-appended after"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.targa)); _ck(_v, 4, 0, currVal_0); }); }
export function View_TargaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-targa", [], null, null, null, View_TargaComponent_0, RenderType_TargaComponent)), i1.ɵdid(1, 49152, null, 0, i3.TargaComponent, [], null, null)], null, null); }
var TargaComponentNgFactory = i1.ɵccf("app-targa", i3.TargaComponent, View_TargaComponent_Host_0, { targa: "targa" }, {}, []);
export { TargaComponentNgFactory as TargaComponentNgFactory };
