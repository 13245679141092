<!-- <app-alert [status]="errorStatus" [message]="errorMessage" *ngIf="errorStatus" (closeAlert)="onCloseAlert()">
</app-alert> -->

<app-loading-spinner *ngIf="isLoading; else contentBlock"></app-loading-spinner>
<ng-template #contentBlock>
  <app-contract-link [targa]="a" [companyId]="b" [anomalyCode]="d"></app-contract-link>
  
  <div *ngIf="anomalyCode.includes(d)">
    <h3 class="text-center text-dark row-margin">
      <!--
        Soluzione da usare in caso di tag HTML più complessi nei file JSON creando una pipe custom
        https://stackoverflow.com/questions/39628007/angular2-innerhtml-binding-remove-style-attribute/39630507
      -->
      <div [innerHtml]="exit_message"></div>
      <!-- {{exit_message}} -->
    </h3>
  </div>

  <div *ngIf="d === '000'; else flussoSmsElse">
    <div class="text-center text-margin-bottom" *ngIf="!isLoading && !errorStatus && !handleErrorMessage">
      <h3 class="text-center mb-5">Abbiamo registrato la tua richiesta riguardo alla targa</h3>
      <app-targa [targa]="a"></app-targa>

      <p class="text-center mb-2">Per ricevere ulteriore assistenza, potrai chiamare il numero</p>
      <h3 class="text-center mb-2">800767878</h3>
      <p class="text-center mb-2">selezionare <span class="font-weight-bold">0</span> e digitare il PIN</p>
      <h3 class="text-center mb-2">{{ p }}</h3>
      <p class="text-center row-margin" *ngIf="resultDays > 1; else resultDaysOneDay">
        che ti darà accesso immediato agli operatori del Call Center e che sarà valido per {{resultDays}} giorni.</p>
      <ng-template #resultDaysOneDay>
        <p class="text-center row-margin">che ti darà accesso immediato agli operatori del Call Center e che sarà valido
          per {{resultDays}} giorno.</p>
      </ng-template>
    </div>
  </div>

  <ng-template #flussoSmsElse>
    <div *ngIf="anomalyCode.includes(d) ">
      <div class="text-center text-margin-bottom" *ngIf="!isLoading && !errorStatus && !handleErrorMessage">
        <h3 *ngIf="d === '009'" class="text-center mb-5">
          Controlleremo nei prossimi giorni se il dispositivo passa in stato "attivo", in caso contrario riceverai un
          SMS
          di anomalia con l'invito a contattare il Call Center o un centro convenzionato per la verifica del
          dispositivo:
          ti invitiamo a seguire le indicazioni contenute nel testo del messaggio.
        </h3>
        <h3 class="text-center mb-5">Per avere un accesso diretto agli operatori del Call Center, sia per informazioni
          che per assistenza per la targa</h3>
        <app-targa [targa]="a"></app-targa>
        <p class="text-center mb-2">annota il seguente codice di accesso personalizzato (PIN)</p>
        <h3 class="text-center mb-2">{{ p }}</h3>
        <p class="text-center row-margin" *ngIf="resultDays > 1">valido per {{resultDays}} giorni
          che puoi utilizzare per ricevere
          assistenza
          chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
        <p class="text-center row-margin">selezionando
          <span class="font-weight-bold">0</span>
          dopo la fine del messaggio di benvenuto
          e digitando il PIN
          <span class="font-weight-bold">{{ p }}</span>
          verrai messo direttamente in contatto con l'operatore
          competente.
        </p>
        <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
        <ul>
          <li class="text-left"> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
          </li>
          <li class="text-left"> Il sabato, dalle ore 9:00 alle ore 12:00.
          </li>
        </ul>
      </div>
    </div>
  </ng-template>

  <div class="mu-error-message-container" *ngIf="handleErrorMessage">
    <!-- <i class="fa fa-exclamation-circle fa-5x text-danger mb-5"></i> -->
    <fa-icon [icon]="faExclamationCircle" class="fa-5x text-danger mb-5"></fa-icon>


    <div class="mu-error-message">
      <h3 class="text-danger">Si è verificato un errore!</h3>
      <p class="text-danger  text-weight-bold mb-5">{{handleErrorMessage}}</p>
      <p class="text-danger">Si prega di riprovare più tardi.</p>
    </div>
  </div>

  <div class="text-center mu-fixed-btn-container" *ngIf="!isSms">
    <button class="btn mu-btn btn-primary" (click)="onBackHome()">Torna alla Home</button>
  </div>
</ng-template>