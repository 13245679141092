<app-contract-link [targa]="a"></app-contract-link>
<div *ngIf="level1Faq; else noLevel1Faq">
  <button class="btn mu-btn mu-floating-btn btn-primary mb-3" (click)="goBack()">
    <!-- <i class="fa fa-arrow-left"></i> -->
    <fa-icon [icon]="faArrowLeft"></fa-icon>
  </button>
  <h3 class="text-center mb-1">
    {{ faqTitle }}
  </h3>
  <p class="text-center mb-3">Clicca sulle voci sottostanti per informazioni più approfondite.</p>
  <p class="text-center mb-3">Seleziona quella più pertinente alla tua esigenza, aggiungi eventuali note e poi clicca su
    "<b>Registra e Procedi</b>":</p>

</div>

<ng-template #noLevel1Faq>
  <button class="btn mu-btn mu-floating-btn btn-primary mb-3" (click)="goBack()">
    <!-- <i class="fa fa-arrow-left"></i> -->
    <fa-icon [icon]="faArrowLeft"></fa-icon>
  </button>
  <h3 class="text-center mb-5">Descrivi nelle note l'argomento per cui richiedi assistenza: </h3>
  <app-targa [targa]="a"></app-targa>
</ng-template>

<!-- 
{{faqs?.faqLivello2 |json}} -->
<ul class="mu-list-group list-group list-group-flush mb-3">
  <li class="mu-list-group-item mu-list-group-item-2nd-level list-group-item" *ngFor="let faq of faqs?.faqLivello2"
    (click)="onClickLevel2Faq(faq)" [ngClass]="faq.isVisible ? 'active' : ''">
    <span class="mu-list-group-item-text">
      <!-- {{ faq.name }} -->
      <div [innerHtml]="faq.name"></div>
      <!-- <i class="fa fa-check-square" *ngIf="faq.isVisible"></i>
      <i class="fa fa-square" *ngIf="!faq.isVisible"></i> -->
      <fa-icon [icon]="faCheckSquare" *ngIf="faq.isVisible"></fa-icon>
      <fa-icon [icon]="faSquare" *ngIf="!faq.isVisible"></fa-icon>
    </span>

    <div class="mu-list-group-item-description" *ngIf="faq.isVisible">
      <p *ngFor="let faqs of faq.faqs; let faqIndex = index;">
        <ng-container *ngIf="faqIndex === 0">
          <!-- {{ faqs.testoFaq }} -->
          <div [innerHtml]="faqs.testoFaq"></div>
        </ng-container>
      </p>
    </div>
  </li>
</ul>

<form class="w-100">
  <div class="textarea-margin-bottom">
    <div class="form-group ">
      <textarea class="mu-form-control mu-textarea form-control" rows="4" id="note" name="note" placeholder="Note..."
        [(ngModel)]="note">
    </textarea>
    </div>
    <div class="form-check" *ngIf="pin?.hasEmail">
      <input class="form-check-input" type="checkbox" id="email" name="email" [(ngModel)]="email">
      <label class="form-check-label" for="email">
        Desideri continuare questo dialogo via email?
      </label>
    </div>
  </div>

  <div class="text-center mu-fixed-btn-container">
    <button class="mu-btn btn btn-primary" id="procediButton" type="submit" [disabled]="!level2Faq && !note"
      (click)="onProcedi($event)">Registra e Procedi</button>
  </div>
</form>