

import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { PinsService } from 'src/app/services/pins.service';
import { Pin } from 'src/app/models/pin.model';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ErrorsService } from 'src/app/services/errors.service';
import { ErrorMeta } from 'src/app/models/errorMeta.model';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss']
})

 /*
   // URLS FOR TESTING
   http://localhost:4200/sms?a=EB588GG&b=U&c=123&d=004
   http://localhost:4200/sms?a=ET441MD&b=U&c=123&d=009
 */
export class PinComponent implements OnInit, OnDestroy{

  @Input() targa: string;
  @Input() problemCode: string;
  @Input() companyId: string;
  @Input() imeiCode: string;
  //MAKE THE EVENT EMITTER ASYNC(true)
  @Output() pinEvent = new EventEmitter<Pin>(true);
  @Output() errorEvent = new EventEmitter<boolean>();

  isLoading = false;
  competence: string;
  hasEmail = false;
  codeMessage: string;
  insuranceStartDate = new Date();
  errorStatus: string;
  errorMessage: string;
  pin: Pin = null;
  endValidity: Date = null;
  handleErrorMessage: string = null;
  isSms = false;
  faExclamationCircle = faExclamationCircle;
  resultDays: number;
  subscriptionPin:Subscription;
  observablePin:Observable<Pin>;
  anomalyCode:string[] = []
  //didSubscribe = true;

  constructor(private pinsService: PinsService, private router: Router, private errorsService: ErrorsService) { }


  ngOnDestroy(){
    console.log('ngOnDestroy pin component')
    if (this.subscriptionPin) {
       this.subscriptionPin.unsubscribe();
    }
  }

  ngOnInit() {

    this.anomalyCode = environment.anomalyCodes

    switch (this.problemCode) {
      case '000':
        break;
      case '004':
      case '009':
      case '007':
      case '016':
      case '017':
         
        this.isSms = true;
        break;
      default:
        // this.errorStatus = 'Non è possibile continuare la navigazione';
        // this.errorMessage = 'Il problem code non è valido';
        this.router.navigate(['error']);

        return;
    }

    this.isLoading = true;

    console.log('[PinComponent] Targa ', this.targa);
    console.log('[PinComponent] companyId ', this.companyId);
    console.log('[PinComponent] problemCode ', this.problemCode);
    console.log('[PinComponent] imeiCode ', this.imeiCode);

    if (this.anomalyCode.includes(this.problemCode)  ) {
      if ((this.targa && this.companyId && this.imeiCode && this.problemCode) !== undefined) {

        let isPinStoredSMS = this.getCurrentPin();
        if(isPinStoredSMS){
          if(isPinStoredSMS.vehiclePlate!= this.targa){
            isPinStoredSMS = null
             const result = this.removeCurrentPinFromStorage()
             if(result) 
             console.log('::::: isPinStored has been removed' );
  
          }
        }
      
        console.log('::::: isPinStored (createPinFlussoSMS)' + isPinStoredSMS);

        if(isPinStoredSMS === null || isPinStoredSMS === undefined){


        this.observablePin  = this.pinsService.createPinFlussoSMS(this.targa, this.companyId, this.imeiCode, this.problemCode);

        this.subscriptionPin =this.observablePin.subscribe(
          response => {

            console.log(' [PinComponent] + pinsService.createPinFlussoSMS(' + this.targa + ') --> ', response);

            this.isLoading = false;

            this.pin = new Pin(
              response.pin,
              this.targa,
              new Date(response.created),
              new Date(response.expirationDate),
              response.competencePlate === undefined ? "M" : response.competencePlate,
              response.email,
              null,
              response.communicationType,
              null,
              null,
              null,
              null,
              null,
              response.codeMessage,
              response.hasEmail,
              response.insuranceStartDate
            );

            this.competence = this.pin.competencePlate;
            this.codeMessage = this.pin.codeMessage;
            this.insuranceStartDate = this.pin.insuranceStartDate;
            this.hasEmail = this.pin.hasEmail;
            this.resultDays = this.pin.daysValidityPin();
            this.endValidity = this.pin.expirationDate;
            console.log(' [PinComponent] + created pin: this.pin --> ', this.pin);
            console.log(' [PinComponent] + codeMessage --> ', this.codeMessage);
            console.log(' [PinComponent] + insuranceStartDate --> ', this.insuranceStartDate);
            console.log(' [PinComponent] + this.competence --> ', this.competence);
            console.log(' [PinComponent] + this.endValidity --> ', this.endValidity);
            console.log(' [PinComponent] + this.hasEmail --> ', this.hasEmail);

            this.storeCurrentPin(response);
            this.pinEvent.emit(this.pin);

          },
          error => {
            this.isLoading = false;

            console.log(' [PinComponent] + creating pins: error --> ', error);
            this.router.navigate(['error']);

          });

      }else {
        //this.didSubscribe = false;
        this.isLoading = false;

        this.pin = new Pin(
          isPinStoredSMS.pin,
          this.targa,
          new Date(isPinStoredSMS.created),
          new Date(isPinStoredSMS.expirationDate),
          isPinStoredSMS.competencePlate === undefined ? "M" : isPinStoredSMS.competencePlate,
          isPinStoredSMS.email,
          null,
          isPinStoredSMS.communicationType,
          null,
          null,
          null,
          null,
          null,
          isPinStoredSMS.codeMessage,
          isPinStoredSMS.hasEmail,
          isPinStoredSMS.insuranceStartDate
        );

        this.competence = this.pin.competencePlate;
        this.codeMessage = this.pin.codeMessage;
        this.insuranceStartDate = this.pin.insuranceStartDate;
        this.hasEmail = this.pin.hasEmail;
        this.resultDays = this.pin.daysValidityPin();
        this.endValidity = this.pin.expirationDate;
        console.log(' ::::: createPinFlussoSMS isPinStored + created pin: this.pin --> ', this.pin);
        console.log(' ::::: createPinFlussoSMS isPinStored + codeMessage --> ', this.codeMessage);
        console.log(' ::::: createPinFlussoSMS isPinStored + insuranceStartDate --> ', this.insuranceStartDate);
        console.log(' ::::: createPinFlussoSMS isPinStored + this.competence --> ', this.competence);
        console.log(' ::::: createPinFlussoSMS isPinStored + response.competencePlate --> ', this.pin.competencePlate);
        console.log(' ::::: createPinFlussoSMS isPinStored + this.endValidity --> ', this.endValidity);
        console.log(' ::::: createPinFlussoSMS isPinStored + this.hasEmail --> ', this.hasEmail);
        console.log(' ::::: createPinFlussoSMS isPinStored + this.resultDays --> ', this.resultDays);


         this.pinEvent.emit(this.pin);



       }
     }
      else {
        this.router.navigate(['error']);
      }
    } else {
      let isPinStored = this.getCurrentPin();
      console.log('::::: isPinStored (createPinFlussoGenerico) ' + isPinStored);

      if(isPinStored === null || isPinStored === undefined){

      this.observablePin  = this.pinsService.createPinFlussoGenerico(this.targa, undefined);
      this.subscriptionPin = this.observablePin
      .subscribe(
        response => {
          console.log(' [PinComponent] + pinsService.createPinFlussoGenerico(' + this.targa + ') --> ', response);
          this.isLoading = false;
          this.pin = new Pin(
            response.pin,
            this.targa,
            new Date(response.created),
            new Date(response.expirationDate),
            response.competencePlate,
            response.email,
            null,
            response.communicationType,
            null,
            null,
            null,
            null,
            null,
            null,
            response.hasEmail,
            response.insuranceStartDate
          );

          this.competence = this.pin.competencePlate;
          this.codeMessage = this.pin.codeMessage;
          this.insuranceStartDate = this.pin.insuranceStartDate;
          this.resultDays = this.pin.daysValidityPin();
          this.endValidity = this.pin.expirationDate;
          console.log(' [PinComponent] + created pin: this.pin --> ', this.pin);
          console.log(' [PinComponent] + codeMessage --> ', this.codeMessage);
          console.log(' [PinComponent] + insuranceStartDate --> ', this.insuranceStartDate);
          console.log(' [PinComponent] + this.competence --> ', this.competence);
          console.log(' [PinComponent] + this.hasEmail --> ', this.pin.hasEmail);
          console.log(' [PinComponent] + this.endValidity --> ', this.endValidity);
          this.storeCurrentPin(response);
          this.pinEvent.emit(this.pin);
        },
        error => {
          this.isLoading = false;

          console.log(' [PinComponent] + creating pins: error --> ', error);
          this.router.navigate(['error']);

        });
    }else {
      console.log('::::: isPinStored (NOT NULL ??) ' + isPinStored);
      //this.didSubscribe = false;
      this.isLoading = false;


      this.pin = new Pin(
        isPinStored.pin,
        this.targa,
        new Date(isPinStored.created),
        new Date(isPinStored.expirationDate),
        isPinStored.competencePlate,
        isPinStored.email,
        null,
        isPinStored.communicationType,
        null,
        null,
        null,
        null,
        null,
        null,
        isPinStored.hasEmail,
        isPinStored.insuranceStartDate
      );

      //test...this.pin.competencePlate = 'I'; 

      this.competence = this.pin.competencePlate;
      this.codeMessage = this.pin.codeMessage;
      this.insuranceStartDate = this.pin.insuranceStartDate;
      this.resultDays = this.pin.daysValidityPin();
      this.endValidity = this.pin.expirationDate;
      console.log(' ::::: createPinFlussoGenerico isPinStored  + created pin: this.pin --> ', this.pin);
      console.log(' ::::: createPinFlussoGenerico isPinStored + codeMessage --> ', this.codeMessage);
      console.log(' ::::: createPinFlussoGenerico isPinStored  + insuranceStartDate --> ', this.insuranceStartDate);
      console.log(' ::::: createPinFlussoGenerico isPinStored  + this.competence --> ', this.competence);
      console.log(' ::::: createPinFlussoGenerico isPinStored + this.hasEmail --> ', this.pin.hasEmail);
      console.log(' ::::: createPinFlussoGenerico isPinStored + this.endValidity --> ', this.endValidity);
      console.log(' ::::: createPinFlussoGenerico isPinStored + this.resultDays --> ', this.resultDays);
      this.pinEvent.emit(this.pin);

        }
    }

  }

  storeCurrentPin(pinToSave: Pin) {
    console.log('[PinComponent] (storeCurrentPin) STORING CURRENT PIN ' + pinToSave);
    sessionStorage.setItem('PinStorage', JSON.stringify(pinToSave));
 }

 getCurrentPin(): Pin {
   let pinSaved: Pin ;
   console.log('[PinComponent]  STORAGE PIN FOUND ???? ', JSON.parse(sessionStorage.getItem('PinStorage')));
   if (sessionStorage.getItem('PinStorage')) {
        pinSaved =  JSON.parse(sessionStorage.getItem('PinStorage'));
        console.log('[PinComponent]  ::::: (getCurrentPin) FETCHING PIN FROM STORAGE pinSaved ' + pinSaved);
   }
   return pinSaved;
}
removeCurrentPinFromStorage() {
  let pinSaved: Pin ;
  if (sessionStorage.getItem('PinStorage')) {
       pinSaved =  JSON.parse(sessionStorage.getItem('PinStorage'));
       console.log('[PinComponent]  ::::: (removeCurrentPinFromStorage) FETCHING PIN FROM STORAGE pinSaved ' + pinSaved);
       sessionStorage.removeItem('PinStorage')
  }
  return true;
}
  onCloseAlert() {

    this.handleErrorMessage = this.errorMessage;

    console.log(' [PinComponent]  + onCloseAlert() --> this.errorStatus --> ', this.errorStatus);
    console.log('[PinComponent]  + onCloseAlert() --> this.errorMessage --> ', this.errorMessage);

    this.errorStatus = null;
    this.errorMessage = null;

    this.errorEvent.emit(true);

    //this.router.navigate(['/home']);
    this.router.navigate(['/home'], { queryParams: { ts: new Date().valueOf() } });

  }
}