import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pin } from 'src/app/models/pin.model';

@Component({
  selector: 'app-device-ok-template',
  templateUrl: './device-ok-template.component.html',
  styleUrls: ['./device-ok-template.component.scss']
})
export class DeviceOkTemplateComponent implements OnInit {
  @Input() pin: Pin = null;
  @Input() targa: string;
  a: string = null;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(p => {
      console.log(' + p --> ', p);

      this.a = p.a;

      console.log(' [DeviceOkTemplateComponent] + ngOnInit() --> this.a (targa)--> ', this.a);
      console.log(" [DeviceOkTemplateComponent] + ngOnInit() --> this.pin.daysValidityPin()-->", this.pin.daysValidityPin());
    });
  }
}
