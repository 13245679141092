<div>
    <h3 class="text-center mb-5">Ti informiamo che il problema per il quale hai ricevuto l’SMS è stato nel frattempo
        risolto.</h3>
    <h3 class="text-center mb-5">Se vuoi comunque ricevere assistenza per la targa </h3>
    <app-targa [targa]="a"></app-targa>
    <p class="text-center row-margin"> annota il seguente codice di accesso personalizzato (PIN)</p>
    <h4 class="text-center row-margin">{{ pin.pin }}</h4>
    <p class="text-center row-margin" *ngIf="pin.daysValidityPin() > 1; else resultDaysOneDay">valido per
        {{pin.daysValidityPin()}} giorni
        che puoi utilizzare per ricevere
        assistenza
        chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
    <ng-template #resultDaysOneDay>
        <p class="text-center row-margin">valido per {{pin.daysValidityPin()}} giorno che puoi utilizzare per ricevere
            che puoi utilizzare per ricevere
            assistenza
            chiamando il Call Center al numero <span class="font-weight-bold">800767878</span></p>
    </ng-template>
    <p class="text-center row-margin">selezionando <span class="font-weight-bold">0</span> e digitando il pin
        <span class="font-weight-bold">{{ pin.pin }}</span> verrai messo direttamente in contatto con l'operatore
        competente.</p>
    <p class="text-center"> Il Call Center è attivo nei seguenti orari: </p>
    <ul class="mb-5">
        <li> Da lunedi al venerdi, dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:30.
        </li>
        <li> Il sabato, dalle ore 9:00 alle ore 12:00.
        </li>
    </ul>
</div>