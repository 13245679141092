/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sms-page-case009.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/contract-link/contract-link.component.ngfactory";
import * as i3 from "../../shared/contract-link/contract-link.component";
import * as i4 from "@angular/router";
import * as i5 from "../device-ok-template/device-ok-template.component.ngfactory";
import * as i6 from "../device-ok-template/device-ok-template.component";
import * as i7 from "../../shared/pin/pin.component.ngfactory";
import * as i8 from "../../shared/pin/pin.component";
import * as i9 from "../../services/pins.service";
import * as i10 from "../../services/errors.service";
import * as i11 from "@angular/common";
import * as i12 from "./sms-page-case009.component";
var styles_SmsPageCase009Component = [i0.styles];
var RenderType_SmsPageCase009Component = i1.ɵcrt({ encapsulation: 0, styles: styles_SmsPageCase009Component, data: {} });
export { RenderType_SmsPageCase009Component as RenderType_SmsPageCase009Component };
function View_SmsPageCase009Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contract-link", [], null, null, null, i2.View_ContractLinkComponent_0, i2.RenderType_ContractLinkComponent)), i1.ɵdid(1, 114688, null, 0, i3.ContractLinkComponent, [i4.Router], { targa: [0, "targa"], companyId: [1, "companyId"], anomalyCode: [2, "anomalyCode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.a; var currVal_1 = _co.b; var currVal_2 = _co.d; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SmsPageCase009Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hai ricevuto un SMS che ti segnala che il dispositivo telematico installato sul tuo veicolo non risulta ancora attivo, potrebbe essere quindi non ancora installato o non funzionante. "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "h3", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Per verificare se \u00E8 necessario eseguire un intervento di diagnosi presso un'officina convenzionata, ti chiediamo di rispondere ad alcune semplici domande: clicca su \""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Procedi"])), (_l()(), i1.ɵted(-1, null, ["\" in fondo alla pagina. "]))], null, null); }
function View_SmsPageCase009Component_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-device-ok-template", [], null, null, null, i5.View_DeviceOkTemplateComponent_0, i5.RenderType_DeviceOkTemplateComponent)), i1.ɵdid(2, 114688, null, 0, i6.DeviceOkTemplateComponent, [i4.ActivatedRoute], { pin: [0, "pin"], targa: [1, "targa"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pin; var currVal_1 = _co.a; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SmsPageCase009Component_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pin", [], null, [[null, "pinEvent"], [null, "errorEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pinEvent" === en)) {
        var pd_0 = (_co.onPinEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("errorEvent" === en)) {
        var pd_1 = (_co.onErrorEvent() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_PinComponent_0, i7.RenderType_PinComponent)), i1.ɵdid(1, 245760, null, 0, i8.PinComponent, [i9.PinsService, i4.Router, i10.ErrorsService], { targa: [0, "targa"], problemCode: [1, "problemCode"], companyId: [2, "companyId"], imeiCode: [3, "imeiCode"] }, { pinEvent: "pinEvent", errorEvent: "errorEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.a; var currVal_1 = _co.d; var currVal_2 = _co.b; var currVal_3 = _co.c; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SmsPageCase009Component_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "margin-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "text-center mu-fixed-btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "btn mu-btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onProcedi() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Procedi"]))], null, null); }
export function View_SmsPageCase009Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase009Component_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase009Component_2)), i1.ɵdid(3, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase009Component_3)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["otherCodeMessage", 2]], null, 0, null, View_SmsPageCase009Component_4)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SmsPageCase009Component_5)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.pin != null); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((!_co.isError && (((_co.pin == null) ? null : _co.pin.codeMessage) !== "DEVICE_OK")) && (_co.pin != null)); _ck(_v, 3, 0, currVal_1); var currVal_2 = (((_co.pin == null) ? null : _co.pin.codeMessage) === "DEVICE_OK"); var currVal_3 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = ((!_co.isError && (((_co.pin == null) ? null : _co.pin.codeMessage) !== "DEVICE_OK")) && (_co.pin != null)); _ck(_v, 8, 0, currVal_4); }, null); }
export function View_SmsPageCase009Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sms-page-case009", [], null, null, null, View_SmsPageCase009Component_0, RenderType_SmsPageCase009Component)), i1.ɵdid(1, 114688, null, 0, i12.SmsPageCase009Component, [i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmsPageCase009ComponentNgFactory = i1.ɵccf("app-sms-page-case009", i12.SmsPageCase009Component, View_SmsPageCase009Component_Host_0, {}, {}, []);
export { SmsPageCase009ComponentNgFactory as SmsPageCase009ComponentNgFactory };
