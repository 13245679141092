import { FaqLivello2 } from './../../models/faq-generico.model';
import { Component, OnInit,OnDestroy } from '@angular/core';
import { FaqService } from '../../services/faq.service';
import { ActivatedRoute, Router,NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { Pin } from 'src/app/models/pin.model';
import { PinsService } from 'src/app/services/pins.service';
import { faqSms } from 'src/app/models/faq-sms.model';
import { FaqLivello1 } from 'src/app/models/faq-generico.model';


@Component({
  selector: 'app-level2-faq',
  templateUrl: './level2-faq.component.html',
  styleUrls: ['./level2-faq.component.scss']
})
export class Level2FaqComponent implements OnInit {

  faqTree: FaqLivello1[] = null;
  a: string = null;
  d: string = null;
  p: number = null;

  level1Faq: number = null;
  level2Faq: any = null;
  faqs: FaqLivello1 = null;
  note: string = null;
  email: string = null;
  pin: Pin = null;
  faArrowLeft = faArrowLeft;
  faCheckSquare = faCheckSquare;
  faSquare = faSquare;
  faqTitle: string;


  constructor(
    private route: ActivatedRoute,
    private faqService: FaqService,
    private router: Router,
    private location: Location,
    private pinsService: PinsService) {


   }


  ngOnInit() {


    this.route.queryParams.subscribe(async p => {
      console.log(' [Level2FaqComponent] + p --> ', p);

      this.a = p.a;
      this.d = p.d;
      this.p = p.p;

      this.level1Faq = p.level1Faq;

      console.log(' [Level2FaqComponent] + ngOnInit() --> this.a (targa) --> ', this.a);
      console.log(' [Level2FaqComponent] + ngOnInit() --> this.d (problemCode)--> ', this.d);

      try {
        this.pin = await this.pinsService.pinData(this.a, this.p, 'G');
      } catch (err) {
        console.log(err);

        return this.router.navigate(['error']);
      }

      if (this.level1Faq) {

        try {
          this.faqTree = (await this.faqService.getFaqFlussoGenerico(this.p, this.a).toPromise() as FaqLivello1[])
        } catch (err) {
          console.log(err);

          return this.router.navigate(['error']);
        }



        // this.faqTree.forEach(faqElement => {
        //   faqElement.isVisible = false;
        // });

        for (let x of this.faqTree) {
          if (x.id == this.level1Faq) {
            this.faqs = x;
            this.faqTitle = x.name
            console.log("Value of x: ", x);
            console.log("Value of x.name: ", x.name);


            this.faqs.faqLivello2.forEach(faqElement => {
              console.log("Value of faqElement: ", faqElement);

              faqElement.isVisible = false;
            });
          }
        }

        console.log(' [Level2FaqComponent] + ngOnInit() --> this.leve1Faq -->', this.level1Faq);
        console.log(' [Level2FaqComponent] + ngOnInit() --> this.faqTree -->', this.faqTree);
        console.log(' [Level2FaqComponent] + ngOnInit() --> level2Faq -->', this.level2Faq);
        console.log(' [Level2FaqComponent] + ngOnInit() --> this.pin.hasEmail -->', this.pin.hasEmail);


      }
    }, err => {
      console.log('Error message --->', err);

      this.router.navigate(['error']);
    });



  }

  goBack(): void {
    this.location.back();
  }

  onClickLevel2Faq(faq: any) {

    console.log(' + onClickLevel2Faq() --> faq -->', faq);

    this.level2Faq = !faq.isVisible ? faq.codiceFaq : null;
    console.log(' + onClickLevel2Faq() --> this.level2Faq -->', this.level2Faq);

    (this.faqs as any).faqLivello2.forEach(faqElement => {
      if (faqElement.name === faq.name) {
        faqElement.isVisible = !faqElement.isVisible;
      } else {
        faqElement.isVisible = false;
      }
    });
  }

  onProcedi(event: PointerEvent) {

    event.preventDefault();

    console.log(' + onProcedi() --> this.level1Faq --> ', this.level1Faq);
    console.log(' + onProcedi() --> this.level2Faq --> ', this.level2Faq);
    console.log(' + onProcedi() --> note --> ', this.note);
    console.log(' + onProcedi() --> email --> ', this.email);

    this.clearSessionStorage();
    this.router.navigate(
      ['pin-page'],
      {
        queryParams: {
          level2Faq: this.level2Faq,
          note: this.note,
          communicationType: this.email !== null && this.email !== undefined ? this.email ? 'si' : 'no' : 'no'
        },
        queryParamsHandling: 'merge'
      }
    );
  }

  clearSessionStorage(){
    if (sessionStorage.getItem('PinStorage')) {
      console.log('::: (clearSessionStorage) CLEARING SESSION STORAGE :::');
      sessionStorage.removeItem('PinStorage');
    }
  }
}
