import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SmsPageCaseComponent } from 'src/app/core/sms-page-case-component';
import { Pin } from 'src/app/models/pin.model';

@Component({
  selector: 'app-sms-page-case016',
  templateUrl: './sms-page-case016.component.html',
  styleUrls: ['./sms-page-case016.component.scss']
})
export class SmsPageCase016Component extends SmsPageCaseComponent implements OnInit {

  constructor(
    route: ActivatedRoute,
    router: Router
  ) {
    super(route, router, 'faq-case016')
  }

  onPinEvent(pin: Pin) {
    super.onPinEvent(pin)
 }
}
